import React, {ComponentProps, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import ReactFlow from 'react-flow-renderer';
import {useNavigate} from 'react-router-dom';
import {useParams} from 'react-router';
import {useTranslation} from 'react-i18next';

import {Button, Checkbox, Input, useTheme} from '@innowise-group/ui-kit';
import {ButtonHelper, ExportFiles} from '@shared-components';
import {Can, Employee, EmployeeWorkspace, PersonContext, RequestOnPlan, useService} from '@innowise-group/core';
import {subject} from '@casl/ability';

import * as Styled from './floor-details.styles';
import {BookingWorkspaceModeForm, BookingWorkspaceModeFormValues} from '../../components/booking-workspace-mode-form';
import {EmployeesList} from '../../components/employees-list';
import {FloorData} from './use-plan-details.hook';
import {FloorPlan, FloorPlanHandler} from '../../components/floor-plan';
import {FloorSelector} from '../../components/floor-selector';
import {FloorsModalsFacadeService} from '../../services/floors-modals-facade';
import {SearchFloorForm, SearchFloorValues} from '../../components/search-floor-form';

interface FloorDetailsProps {
  Map?: React.FC<ComponentProps<typeof ReactFlow> & {isEditMode: boolean}>;
  requestData?: RequestOnPlan;
  focusWorkspace?: Pick<EmployeeWorkspace, 'id' | 'floorId'>;
}

const FloorDetails: React.FC<FloorDetailsProps> = ({requestData, focusWorkspace, Map = ReactFlow}) => {
  const theme = useTheme();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isShowSecondPlan, setIsShowSecondPlan] = useState(false);
  const [floorData, setFloorData] = useState<FloorData>({} as FloorData);
  const [isPlanExists, setIsPlanExists] = useState(false);
  const [focus, setFocus] = useState(true);
  const [searchName, setSearchName] = useState('');
  const [secondFloorId, setSecondFloorId] = useState('');
  const [secondFloorData, setSecondFloorData] = useState<FloorData | null>(null);
  const [page, setPage] = useState(1);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [selectedBookingDetails, setSelectedBookingDetails] = useState<BookingWorkspaceModeFormValues>(null);
  const [formSelectedOfficeId, setFormSelectedOfficeId] = useState<string | undefined>();
  const [isFirstPlanLoading, setIsFirstPlanLoading] = useState<boolean>(false);
  const [isSecondPlanLoading, setIsSecondPlanLoading] = useState<boolean>(false);
  const {t} = useTranslation();
  const {id: floorId} = useParams();
  const navigate = useNavigate();
  const floorsModalsFacade = useService(FloorsModalsFacadeService);
  const firstPlanRef = useRef<FloorPlanHandler>(null);
  const secondPlanRef = useRef<FloorPlanHandler>(null);
  const handleRef = useRef<FloorPlanHandler>(null);
  const person = useContext(PersonContext);

  const closeDisplayingSecondPlan = () => {
    setIsShowSecondPlan(false);
    setSecondFloorId('');
  };

  const handleSearchFloorSubmit = (values: SearchFloorValues) => firstPlanRef.current?.handleSearchFloorSubmit(values);

  const handleSecondPlanFloorChange = ({floor: floorId}: SearchFloorValues) => {
    setSecondFloorId(floorId);
    secondPlanRef.current?.handleSecondPlanFloorChange();
  };

  const handleSaveChanges = () => {
    firstPlanRef.current?.saveChanges();
    secondPlanRef.current?.saveChanges();
  };

  const handleAbortChanges = () => {
    firstPlanRef.current?.abortChanges();
    secondPlanRef.current?.abortChanges();
  };

  const handleUploadPlan = () => firstPlanRef.current?.handleUploadPlan();
  const handleBookingWorkspaceMode = (values: BookingWorkspaceModeFormValues) => {
    if ((values.bookingMode && values.startDay && values.endDay) || !values.bookingMode) {
      setSelectedBookingDetails(values);
    }
  };

  const handleAddNewWorkspace = useCallback(() => {
    const isVirtualFloor = secondFloorId && !focus ? secondFloorData?.isVirtual : floorData?.isVirtual;

    handleRef.current.handleAddNewWorkspace(isVirtualFloor);
  }, [floorData?.isVirtual, focus, secondFloorData?.isVirtual, secondFloorId]);

  const handleBookWorkspace = () => firstPlanRef.current?.handleBookWorkspace();
  const handleDownloadCSV = useCallback(() => handleRef.current.downloadCSV(), []);
  const handleDownloadPDF = useCallback(() => handleRef.current.downloadPDF(), []);
  const handleDownloadXLSX = useCallback(() => handleRef.current.downloadXLSX(), []);

  useEffect(() => {
    focus ? (handleRef.current = firstPlanRef.current) : (handleRef.current = secondPlanRef.current);
  }, [focus]);

  useEffect(() => {
    if (floorData?.address) {
      setFormSelectedOfficeId(floorData.address);
    }
  }, [floorData]);

  const handleEditToggle = () => {
    setIsEditMode((value) => !value);
    handleBookingWorkspaceMode({startDay: '', endDay: '', bookingMode: false});
    if (isEditMode) {
      handleSaveChanges();
      setPage(1);
      closeDisplayingSecondPlan();
    }
  };

  const handleCancelClick = () => {
    setIsEditMode((value) => !value);
    handleAbortChanges();
    setPage(1);
    closeDisplayingSecondPlan();
  };

  const handleSearchName = (event: React.FormEvent<HTMLOrSVGElement & HTMLInputElement>) => {
    setSearchName(event.currentTarget.value);
    setPage(1);
  };

  const handleShowSecondPlan = () => {
    if (!isShowSecondPlan) {
      floorsModalsFacade.showSecondPlanForm(t('modals.chooseOffice'), person.allowed_locations).subscribe((value) => {
        setIsShowSecondPlan(true);
        setSecondFloorId(value.floor);
      });
    } else {
      setFocus(true);
      closeDisplayingSecondPlan();
    }
  };

  const handleNavigateBack = useCallback(() => {
    const floorOfficeId = formSelectedOfficeId || floorData?.address || localStorage.getItem('savedOfficeId');
    navigate(`/offices/${floorOfficeId}`);
  }, [floorData.address, formSelectedOfficeId, navigate]);

  const focusOnFirstPlan = useCallback((floor: FloorData) => {
    setFocus(true);
    setFloorData(floor);
  }, []);
  const focusOnSecondPlan = useCallback((floor: FloorData) => {
    setFocus(false);
    setSecondFloorData(floor);
  }, []);

  const buttons = useMemo(() => {
    return [
      {callback: handleDownloadCSV, buttonName: 'downloadCSVReport'},
      {callback: handleDownloadPDF, buttonName: 'downloadPDFReport'},
      {callback: handleDownloadXLSX, buttonName: 'downloadXLSXReport'},
    ];
  }, [handleDownloadCSV, handleDownloadPDF, handleDownloadXLSX]);

  const allowNotifications = useCallback(() => {
    if (person?.allowed_locations) {
      const floor = secondFloorId && !focus ? secondFloorData : floorData;
      return person?.allowed_locations[floor?.country]?.some((id) => id === Number(floor?.address));
    }
  }, [person?.allowed_locations, secondFloorId, focus, secondFloorData, floorData]);

  return (
    <Styled.FloorDetailsContainer
      title={
        !requestData?.showOnPlan &&
        !focusWorkspace && (
          <Styled.FloorTitleContainer>
            <Styled.FloorTitleRow className="space-between">
              <ButtonHelper text={t('buttons.back')} icon="u_angle-left-b" noExtraMargin onClick={handleNavigateBack} />
              {isPlanExists && (
                <Can I="UPDATE" this={subject('FLOOR', {id: floorId})}>
                  <ExportFiles buttons={buttons} helperColor="#3179E3" />
                </Can>
              )}
            </Styled.FloorTitleRow>
            {isPlanExists && (
              <Styled.FloorTitleRow className="float">
                {!isEditMode ? (
                  <Styled.SearchFloorFormContainer>
                    {floorData?.floor && (
                      <SearchFloorForm
                        onSubmit={handleSearchFloorSubmit}
                        onAddressChanged={setFormSelectedOfficeId}
                        initialValues={floorData}
                      />
                    )}
                  </Styled.SearchFloorFormContainer>
                ) : (
                  <Styled.SearchEmployeeContainer>
                    <Input placeholder={t('placeholders.search')} onChange={handleSearchName} />
                  </Styled.SearchEmployeeContainer>
                )}
                {!isEditMode && (
                  <Styled.BookingWorkspaceFormContainer>
                    <BookingWorkspaceModeForm onSubmit={handleBookingWorkspaceMode} />
                  </Styled.BookingWorkspaceFormContainer>
                )}
                <Styled.ActionButtonsContainer>
                  {isEditMode && (
                    <>
                      <Can I="CREATE-PLAN" this={subject('FLOOR', {id: floorId})}>
                        <Button onClick={handleUploadPlan} icon="u_arrow-up">
                          {t('buttons.uploadPlan')}
                        </Button>
                      </Can>
                      <Button
                        icon="u_plus"
                        onClick={handleAddNewWorkspace}
                        withLoader={isFirstPlanLoading || isSecondPlanLoading}
                        disabled={isFirstPlanLoading || isSecondPlanLoading}
                      >
                        {t('buttons.addWorkspace')}
                      </Button>
                    </>
                  )}
                  <Can I="UPDATE" this={subject('FLOOR', {id: floorId})} passThrough>
                    {(allowed) =>
                      allowed ? (
                        floorData?.floor && (
                          <Button
                            onClick={handleEditToggle}
                            icon={isEditMode ? undefined : 'u_edit-alt'}
                            iconSize={16}
                            variant="outlined"
                            color={theme.palette.generalAlt.font.tertiary}
                            iconColor={theme.palette.generalAlt.font.tertiary}
                          >
                            {isEditMode ? t('buttons.save') : t('buttons.edit')}
                          </Button>
                        )
                      ) : (
                        <Button onClick={handleBookWorkspace} icon="u_bookmark" iconSize={16}>
                          {t('buttons.bookWorkspace')}
                        </Button>
                      )
                    }
                  </Can>
                  <Can I="UPDATE" this={subject('FLOOR', {id: floorId})}>
                    {isEditMode ? (
                      <Button variant="outlined" onClick={handleCancelClick}>
                        {t('buttons.cancel')}
                      </Button>
                    ) : null}
                  </Can>
                </Styled.ActionButtonsContainer>
              </Styled.FloorTitleRow>
            )}
            {isEditMode && isPlanExists && (
              <Styled.FloorTitleRow>
                <Can I="UPDATE" this={subject('FLOOR', {id: floorId})}>
                  <Checkbox checked={isShowSecondPlan} onChange={handleShowSecondPlan}>
                    {t('buttons.twoPlans')}
                  </Checkbox>
                </Can>
              </Styled.FloorTitleRow>
            )}
          </Styled.FloorTitleContainer>
        )
      }
    >
      {isEditMode && (
        <EmployeesList
          employees={employees}
          name={searchName}
          setEmployees={setEmployees}
          page={page}
          setPage={setPage}
          setSearchName={setSearchName}
        />
      )}
      <Styled.PlanContainer>
        <FloorSelector
          disabled={isFirstPlanLoading}
          officeId={formSelectedOfficeId}
          onFloorChanged={handleSearchFloorSubmit}
        />
        <Styled.Legend isVirtual={floorData?.isVirtual} />
        <FloorPlan
          Map={Map}
          ref={firstPlanRef}
          floorId={floorId}
          requestData={requestData}
          focusWorkspace={focusWorkspace}
          isEditMode={isEditMode}
          setEmployees={setEmployees}
          setFloorData={setFloorData}
          isShowSecondPlan={isShowSecondPlan}
          onFocusClick={focusOnFirstPlan}
          focus={focus}
          showNotifications={allowNotifications()}
          setIsPlanExists={setIsPlanExists}
          onLoadingStateChanged={setIsFirstPlanLoading}
          selectedBookingDetails={selectedBookingDetails}
        />
        {isShowSecondPlan && secondFloorId && (
          <Styled.SecondPlanContainer>
            <FloorPlan
              Map={Map}
              ref={secondPlanRef}
              floorId={secondFloorId}
              isEditMode={isEditMode}
              setEmployees={setEmployees}
              isShowSecondPlan={isShowSecondPlan}
              onFocusClick={focusOnSecondPlan}
              onLoadingStateChanged={setIsSecondPlanLoading}
              focus={!focus}
              showNotifications={allowNotifications()}
            />
            <FloorSelector
              disabled={isSecondPlanLoading}
              secondFloorId={secondFloorId}
              onFloorChanged={handleSecondPlanFloorChange}
            />
          </Styled.SecondPlanContainer>
        )}
      </Styled.PlanContainer>
    </Styled.FloorDetailsContainer>
  );
};

export default React.memo(FloorDetails);
