import {Button, TableHead, Tabs, styled} from '@innowise-group/ui-kit';

import {DatePickerContainer} from '../../../../shared/ui-kit/custom-date-picker';

export const TabPanelContent = styled('div')`
  padding: 32px 20px 20px 20px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  min-height: 160px;
`;

export const ControlsBar = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

export const OverridenDatePickerContainer = styled(DatePickerContainer)`
  width: 300px;
`;

export const ExportButton = styled(Button)`
  font-size: 16px;
  font-weight: 600;
  border: none;
  font-family: Manrope;
  line-height: 24px;
  width: auto;
  margin: 0;
`;

export const StaticTabs = styled(Tabs)`
  margin: 0;
`;

export const StaticTableHead = styled(TableHead)`
  width: 129px;
`;

export const NoResults = styled('p')`
  font-size: 14px;
`;
