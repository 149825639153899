import {Observable} from 'rxjs';
import {Location, Office} from '@innowise-group/core';
import {FilterData} from './geotrack-filters.types';
import {
  ChatbotStatuses,
  EmployeeStatusesData,
  EmployeeStatusesResponse,
  EmployeeAnalyticsData,
  EmployeeStatisticsData,
  HrmDictionary,
  OrgUnit,
  EmployeeVisitsResponse,
  EmployeeVisitByDate,
} from './geotrack-normalization.types';
import {AxiosRequestConfig} from 'axios';

export interface RequestParams {
  filters: FilterData;
  currentPage: number;
  pageSize: number;
  searchName: string;
}

export interface GeotrackApi {
  getOrgUnitTypes: () => Observable<OrgUnit[]>;
  getChatbotStatuses: () => Observable<ChatbotStatuses>;
  getHrmDictionaryEntries: (...keys: HrmDictionaryKey[]) => Observable<HrmDictionary[]>;
  getReport: (params: RequestParams) => Observable<File>;
  getEmployeeStatuses: (params: RequestParams) => Observable<{data: EmployeeStatusesData[]; totalElements: number}>;
  getEmployeeAnalytics: (params: RequestParams) => Observable<{data: EmployeeAnalyticsData[]; totalElements: number}>;
  getEmployeeStatistics: (params: RequestParams) => Observable<EmployeeStatisticsData>;
  getRegisteredOffices: () => Observable<RegisteredOffice[]>;
  getAvailableFilterValues: () => Observable<AvailableFilterValues>;
  getEmployeeVisitsByDates: (
    filters: EmployeeVisitsRequestParams,
    config?: AxiosRequestConfig,
  ) => Observable<EmployeeVisitsResponse>;
  getDetailsEmployeeVisitByDate: (
    filters: EmployeeVisitRequestParams,
    config?: AxiosRequestConfig,
  ) => Observable<EmployeeVisitByDate | ErrorNotFoundEmployeeVisit>;
}

interface HrmDictionaryResponseData {
  id: string;
  name: string;
  values: {id: string; orderValue: number; value: string; translations: {translation: string}[]}[];
}

interface ChatbotStatusResponseData {
  id: number;
  name: string;
}

export type HrmDictionaryResponse = HrmDictionaryResponseData[];
export type ChatbotStatusResponse = ChatbotStatusResponseData[];

export type DictionaryMap = {[key in HrmDictionaryKey]: HrmDictionary};

export interface OrgUnitResponse {
  data: {
    orgUnitDtoList: OrgUnit[];
  };
}

export type HrmDictionaryKey =
  | 'timeOffType'
  | 'employeeStatus'
  | 'jobTitle'
  | 'formatOfWork'
  | 'officeAddress'
  | 'country'
  | 'managerialLevel'
  | 'professionalLevel'
  | 'probationStatus';

export interface RegisteredOffice {
  id: number;
  addressId: string;
  countryCode: string;
}

export interface AvailableFilterValues {
  officeIdList: number[];
}

export interface GeotrackApiResponse<T> {
  results: T[];
  count: number;
  previous: string;
  next: string;
}

export interface EmployeeStatusesAnalyticResponse extends EmployeeStatusesResponse {
  analitics_list: {
    employee_id: number;
    confirmed_visit: number;
    unconfirmed_visit: number;
    remotely: number;
    absence: number;
  };
}

export interface ReferenceValues {
  orgUnits: OrgUnit[];
  registeredCountries: string[];
  registeredOffices: RegisteredOffice[];
  addresses: HrmDictionary;
  locations: Location[];
  offices: Office[];
  hrmStatuses: HrmDictionary;
  workFormats: HrmDictionary;
  positions: HrmDictionary;
  chatbotStatuses: ChatbotStatuses;
  professionalLevels: HrmDictionary;
  managerialLevels: HrmDictionary;
  probationStatuses: HrmDictionary;
  unitsList: HrmDictionary;
  divisionsList: HrmDictionary;
  departmentsList: HrmDictionary;
}

export interface EmployeeVisitsRequestParams {
  hrm_id: number;
  date_range: string[];
}
export interface EmployeeVisitRequestParams {
  hrm_id: number;
  date: string;
}

export enum VisitTypes {
  CONFIRMED_VISIT = 'Confirmed Visit',
  UNCONFIRMED_VISIT = 'Unconfirmed Visit',
  REMOTE_WORK = 'Remote Work',
  ABSENT = 'Absent',
  REMOTE_WORK_WITH_VISIT = 'Remote Work with Visit',
  NOT_DEFINED = 'Not defined',
}
type VisitChartTypes = {
  [key: string]: {
    visit_type: VisitTypes;
  };
};
type WorkOffendersChartTypes = {
  [key: string]: {
    is_offender: boolean;
  };
};
type LateComersChartType = {
  [key: string]: boolean;
};
export interface EmployeeVisitsByDatesResponse {
  visit_types_chart: VisitChartTypes;
  latecomers_chart: LateComersChartType;
  work_format_offenders_chart: WorkOffendersChartTypes;
}
export interface EmployeeVisitByDateResponse {
  status: string;
  chat_bot_status: string;
  has_faceid: boolean;
  enter_date_time_only: string | null | number;
  definition_office_address: string | null;
  definition_office_city: string | null;
  office_hours: number;
  office_minutes: number;
  status_eng: string;
  definition_office_address_eng: string | null;
  definition_office_city_eng: string | null;
}
export type ErrorNotFoundEmployeeVisit = {
  payload: {
    error: string;
  };
  statusErr: number;
};
