import {useCallback, useContext, useMemo, useState} from 'react';
import {
  BookedStatus,
  CreateWorkspaceRequestPayload,
  PersonContext,
  RequestsApiService,
  useService,
  WorkspaceBookType,
  WorkspacesApiService,
} from '@innowise-group/core';
import {Elements} from 'react-flow-renderer';
import {useTranslation} from 'react-i18next';
import {format, parse, setHours} from 'date-fns';
import {BehaviorSubject, finalize} from 'rxjs';
import {BookingWorkspaceModeFormValues, DateRange} from '../../components/booking-workspace-mode-form';
import {WorkspaceElementData} from '../../components/workspace-view';
import {FloorsModalsFacadeService} from '../../services/floors-modals-facade';
import {mapWorkspaceToElements} from '../../utilities/element-mapper.utility';
import {FloorData} from './use-plan-details.hook';
import {FloorsEventEmitterService} from '../../services/floors-event-emitter';
import {BookingWorkspaceValues, RequestType} from '@shared-components';

export interface UseWorkspaceBookingResult {
  bookingWorkspaceMode: (data: BookingWorkspaceModeFormValues) => void;
  bookWorkspace: () => void;
  bookChosenWorkspace: (workspaceId: string, roomId: string) => void;
  bookWorkspaceDateRange: {range: DateRange};
}

export const useWorkspaceBooking = (
  {floor, address}: FloorData,
  setIsPlanLoading: (value: boolean) => void,
  floorId: string,
  workspaces$: BehaviorSubject<Elements<WorkspaceElementData>>,
  floorsEventEmitter: FloorsEventEmitterService,
): UseWorkspaceBookingResult => {
  const person = useContext(PersonContext);
  const {t} = useTranslation();
  const [bookWorkspaceDateRange, setBookWorkspaceDateRange] = useState<{range: DateRange}>(null);
  const workspacesApi = useService(WorkspacesApiService);
  const requestsApi = useService(RequestsApiService);
  const floorsModalsFacade = useService(FloorsModalsFacadeService);
  const workspacesValues = workspaces$.getValue();

  const workspaceList = useMemo(() => {
    return workspacesValues.map(({data: {id, number, bookedStatus, activeRequests, status, room}}) => {
      const excludeDates = bookedStatus.map((status) => ({
        employeeId: status.employee.id,
        range: {
          start: status.range.startDay ? parse(status.range.startDay, 'dd.MM.yyyy', new Date()) : null,
          end: status.range.endDay ? parse(status.range.endDay, 'dd.MM.yyyy', new Date()) : null,
        },
      }));
      const activeDates = activeRequests.map((status) => ({
        employeeId: status.employee.id,
        range: {
          start: status.range.startDay ? parse(status.range.startDay, 'dd.MM.yyyy', new Date()) : null,
          end: status.range.endDay ? parse(status.range.endDay, 'dd.MM.yyyy', new Date()) : null,
        },
      }));
      return {id, excludeDates, activeDates, status, room, number};
    });
  }, [workspacesValues]);

  const createWorkspaceRequest = useCallback(
    ({seat, startDay, endDay, comment, requestType}: BookingWorkspaceValues) => {
      const payload: CreateWorkspaceRequestPayload = {
        workspace: seat && Number(seat),
        request_type: requestType,
        employee: person?.id && Number(person?.id),
        comment,
      };

      if (requestType === RequestType.TemporalOccupation) {
        payload.occupation_start_date = startDay && setHours(new Date(startDay), 9).toISOString();
        payload.occupation_end_date = endDay && setHours(new Date(endDay), 18).toISOString();
      }

      return requestsApi.createWorkspaceRequest(payload).pipe(
        finalize(() => {
          floorsEventEmitter.emit('list');
        }),
      );
    },
    [person?.id, requestsApi, floorsEventEmitter],
  );

  const bookWorkspace = useCallback(() => {
    floorsModalsFacade.openBookingWorkspaceForm(
      createWorkspaceRequest,
      {address, floor, ...bookWorkspaceDateRange?.range},
      t('modals.requestWorkspaceTitle'),
      t('buttons.book'),
      person,
      workspaceList,
    );
  }, [
    bookWorkspaceDateRange?.range,
    createWorkspaceRequest,
    address,
    floor,
    floorsModalsFacade,
    person,
    t,
    workspaceList,
  ]);

  const bookChosenWorkspace = useCallback(
    (workspaceId: string, roomId: string) => {
      floorsModalsFacade.openBookingWorkspaceForm(
        createWorkspaceRequest,
        {address, floor, room: roomId, seat: workspaceId, ...bookWorkspaceDateRange?.range},
        t('modals.requestWorkspaceTitle'),
        t('buttons.book'),
        person,
        workspaceList,
      );
    },
    [
      floorsModalsFacade,
      createWorkspaceRequest,
      address,
      floor,
      bookWorkspaceDateRange?.range,
      t,
      person,
      workspaceList,
    ],
  );

  const bookingWorkspaceMode = useCallback(
    (data: BookingWorkspaceModeFormValues) => {
      const {startDay, endDay, bookingMode} = data;
      if (bookingMode && startDay && endDay) {
        const startDayFormatted = format(new Date(startDay), 'yyyy-MM-dd');
        const endDayFormatted = format(new Date(endDay), 'yyyy-MM-dd');
        setIsPlanLoading(true);
        workspacesApi
          .bookingWorkspaceMode(floorId, startDayFormatted, endDayFormatted)
          .pipe(
            finalize(() => {
              setIsPlanLoading(false);
            }),
          )
          .subscribe((workspaces) => {
            const bookedWorkspaces = workspaces.map((workspace) => {
              return workspace.isBooked
                ? {...workspace, status: 'Booked' as WorkspaceBookType}
                : {...workspace, status: 'Free' as WorkspaceBookType, bookedStatus: [] as BookedStatus[]};
            });
            workspaces$.next(mapWorkspaceToElements(bookedWorkspaces, true));
            setBookWorkspaceDateRange({range: {startDay, endDay}});
          });
      }

      if (bookingMode && (startDay === null || endDay === null)) {
        floorsEventEmitter.emit('list');
        setIsPlanLoading(false);
        setBookWorkspaceDateRange(null);
      }

      if (!bookingMode) {
        floorsEventEmitter.emit('list');
        setIsPlanLoading(false);
        setBookWorkspaceDateRange(null);
      }
    },
    [setIsPlanLoading, workspacesApi, floorId, workspaces$, floorsEventEmitter],
  );

  return {
    bookingWorkspaceMode,
    bookWorkspace,
    bookChosenWorkspace,
    bookWorkspaceDateRange,
  };
};
