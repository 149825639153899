import {Observable} from 'rxjs';
import {injectable} from '@innowise-group/core';
import {modalManager, Modal, Typography} from '@innowise-group/ui-kit';

import {OfficeDetailsAttendanceProvider} from '../office-details-attendance.context';
import {AddLocationFormValues} from '../../components/add-location-form';
import {AddLocationForm} from '../../components/add-location-form';
import {AddOfficeFormValues, AddOfficeForm, AddOfficeFormSection} from '../../components/add-office-form';
import {AttendanceStatisticsContainer} from '../../components/attendance-statistics-container';
import {OfficesContainerTypes} from '../offices-ioc.types';
import {OfficesModalsFacade} from './offices-modals-facade.types';

@injectable()
class OfficesModalsFacadeService implements OfficesModalsFacade {
  public static readonly type = OfficesContainerTypes.OfficesModalsFacade;

  public openDeleteLocationModal(
    title?: string,
    text?: string,
    confirmationText?: string,
    cancelText?: string,
  ): Observable<void> {
    return new Observable((observer) => {
      modalManager.confirmationModal({
        text,
        title,
        confirmationText,
        cancelText,
        onApprove: () => {
          observer.next();
        },
        onComplete: () => {
          observer.complete();
        },
      });
    });
  }

  public openDeleteOfficeModal(
    title?: string,
    text?: string,
    confirmationText?: string,
    cancelText?: string,
  ): Observable<void> {
    return new Observable((observer) => {
      modalManager.confirmationModal({
        text,
        title,
        confirmationText,
        cancelText,
        onApprove: () => {
          observer.next();
        },
        onComplete: () => {
          observer.complete();
        },
      });
    });
  }

  public openDeleteFloorModal(
    title?: string,
    text?: string,
    confirmationText?: string,
    cancelText?: string,
  ): Observable<void> {
    return new Observable((observer) => {
      modalManager.confirmationModal({
        text,
        title,
        confirmationText,
        cancelText,
        onApprove: () => {
          observer.next();
        },
        onComplete: () => {
          observer.complete();
        },
      });
    });
  }

  public openAddLocationModal(handler: (values: AddLocationFormValues) => Observable<unknown>, title?: string): void {
    modalManager.open(({close}) => (
      <Modal.Container>
        <Modal.Header title={title} onClose={close} />
        <Modal.Body>
          <AddLocationForm onSubmit={handler} onCancel={close} />
        </Modal.Body>
      </Modal.Container>
    ));
  }

  public openAddOfficeModal(
    handler: (values: AddOfficeFormValues) => Observable<unknown>,
    initialValues?: Partial<AddOfficeFormValues>,
    modalConfig: {title: string; valuesToShow?: AddOfficeFormSection[]} = {title: 'Add new office'},
  ): void {
    modalManager.open(({close}) => (
      <Modal.Container>
        <Modal.Header title={modalConfig.title} onClose={close} />
        <Modal.Body>
          <AddOfficeForm
            sectionsToShow={modalConfig.valuesToShow}
            initialValues={initialValues}
            onSubmit={handler}
            onCancel={close}
          />
        </Modal.Body>
      </Modal.Container>
    ));
  }

  public openToggleVirtualFloorModal(
    title?: string,
    text?: string,
    confirmationText?: string,
    cancelText?: string,
  ): Observable<void> {
    return new Observable((observer) => {
      modalManager.confirmationModal({
        text,
        title,
        confirmationText,
        cancelText,
        onApprove: () => {
          observer.next();
        },
        onComplete: () => {
          observer.complete();
        },
      });
    });
  }

  public openInfoModal(text: string, title: string, button: string): void {
    modalManager.baseModal(<Typography>{text}</Typography>, {title, button});
  }

  public openAttendanceStatisticsModal(title: string, officeId: string): void {
    modalManager.open(({close}) => (
      <Modal.Container noPadding>
        <Modal.Header title={title} onClose={close} hideBottomBorder />
        <Modal.Body noPadding>
          <OfficeDetailsAttendanceProvider officeId={officeId}>
            <AttendanceStatisticsContainer />
          </OfficeDetailsAttendanceProvider>
        </Modal.Body>
      </Modal.Container>
    ));
  }
}

export default OfficesModalsFacadeService;
