import {format} from 'date-fns';
import {map, Observable, tap} from 'rxjs';
import capitalize from 'lodash/capitalize';

import {getLocaleByLanguage} from '@shared/utilities/get-language-from-ls.utility';
import {notificationsManager} from '@innowise-group/ui-kit';

import {AddOfficeFormValues} from '../../../../modules/offices/components/add-office-form';
import {OfficesNormalization} from '../offices-normalization';

import {
  OfficesApi,
  DetailsListOfficeResponse,
  FloorStatisticResponse,
  OfficeStatisticsMonthlyAttendanceResponse,
  OfficeStatisticsDailyAttendanceResponse,
  OfficeStatisticsMonthlyAttendanceRequest,
  OfficeStatisticsDailyAttendanceRequest,
  OfficeShortAttendancetatisticsResponse,
  OfficeStatisticsAvailableYearsRequest,
  OfficeStatisticsAvailableYearsResponse,
} from './offices-api.types';

import {
  Floor,
  GlobalContainerTypes,
  Http,
  Office,
  OfficeDetails,
  OfficeResponse,
  OfficeStatistic,
  OfficeStatisticResponse,
  inject,
  injectable,
} from '../..';

@injectable()
class OfficesApiService implements OfficesApi {
  public static readonly type = GlobalContainerTypes.OfficesApi;

  @inject(GlobalContainerTypes.Http) private http: Http;
  @inject(GlobalContainerTypes.OfficesNormalization) private officesNormalization: OfficesNormalization;

  public addOffice({
    location,
    address,
    address_eng,
    index,
    floors,
    managers,
    jira_office_id,
  }: AddOfficeFormValues): Observable<Office> {
    return this.http
      .POST<DetailsListOfficeResponse>('/offices/', {
        location: Number(location),
        address,
        address_eng,
        postcode: index,
        jira_office_id: Number(jira_office_id),
        floors_numbers: floors.map(({number, isVirtual}) => ({number, is_virtual: isVirtual})),
        manager: managers ?? [],
      })
      .pipe(
        map(({id, location, address, postcode}) =>
          this.officesNormalization.normalizeListOfficeFromApi(id, location, postcode, address, address_eng),
        ),
        tap(() => {
          notificationsManager.success({title: 'success', subtitle: 'addOffice'});
        }),
      );
  }

  public editOffice(
    officeId: string,
    {address, address_eng, floors, index, location, managers, jira_office_id}: AddOfficeFormValues,
    silent?: boolean,
  ): Observable<Office> {
    const observable = this.http
      .PUT<DetailsListOfficeResponse>(`/offices/${officeId}/`, {
        location: Number(location),
        address,
        address_eng,
        postcode: index,
        floors_numbers: floors.map(({number, isVirtual}) => ({number, is_virtual: isVirtual})),
        jira_office_id: Number(jira_office_id),
        manager: managers ?? [],
      })
      .pipe(
        map(({location, address, postcode}) =>
          this.officesNormalization.normalizeListOfficeFromApi(
            Number(officeId),
            location,
            postcode,
            address,
            address_eng,
          ),
        ),
      );

    return silent
      ? observable
      : observable.pipe(
          tap(() => {
            notificationsManager.success({title: 'success', subtitle: 'editOffice'});
          }),
        );
  }

  public getOfficeById(officeId: string): Observable<OfficeDetails> {
    return this.http
      .GET<OfficeResponse>(`/offices/${officeId}/`)
      .pipe(map(this.officesNormalization.normalizeOfficeInfoFromApi));
  }

  public getOfficeShortStatistics(officeId: string): Observable<{[key: string]: number}> {
    return this.http.GET<OfficeShortAttendancetatisticsResponse>(`/offices/${officeId}/office-statistics-short/`).pipe(
      map((statistics) => {
        return statistics.maximum_occupancy_percentage;
      }),
    );
  }

  public getOffices(): Observable<Office[]> {
    return this.http
      .GET<DetailsListOfficeResponse[]>('/offices/office-statistic/')
      .pipe(
        map((list) =>
          list.map(
            ({
              id,
              location,
              address,
              address_eng,
              postcode,
              percent_of_free_workspaces,
              number_of_reserved_workspaces,
              number_of_remote_workspaces,
              number_of_booked_workspaces,
              number_of_occupied_workspaces,
              number_of_free_workspaces,
              has_faceid,
              jira_office_id,
            }) =>
              this.officesNormalization.normalizeListOfficeFromApi(
                id,
                location,
                postcode,
                address,
                address_eng,
                Math.round(
                  100 -
                    (number_of_reserved_workspaces ||
                    number_of_free_workspaces ||
                    number_of_remote_workspaces ||
                    number_of_booked_workspaces ||
                    number_of_occupied_workspaces
                      ? percent_of_free_workspaces
                      : 100),
                ) || 0,
                jira_office_id,
                has_faceid,
              ),
          ),
        ),
      );
  }

  public deleteOffice(officeId: string): Observable<void> {
    return this.http.DELETE(`/offices/${officeId}/`).pipe(
      tap<void>(() => {
        notificationsManager.success({title: 'success', subtitle: 'deleteOffice'});
      }),
    );
  }

  public getOfficeFloors(officeId: string): Observable<Floor[]> {
    return this.http.GET<FloorStatisticResponse>(`/offices/${officeId}/floors-statistic/`).pipe(
      tap((list) =>
        list.sort(
          (firstItem, secondItem) =>
            firstItem.number - secondItem.number || +firstItem.is_virtual - +secondItem.is_virtual,
        ),
      ),
      map(this.officesNormalization.normalizeOfficeFloorsFromApi),
    );
  }

  public getOverallStatisticOffices(): Observable<OfficeStatistic[]> {
    return this.http.GET<OfficeStatisticResponse[]>(`/offices/statistics/`).pipe(
      map((list) =>
        list.map(
          ({
            id,
            number_of_all_workspaces,
            number_of_free_workspaces,
            number_of_reserved_workspaces,
            number_of_occupied_workspaces,
            number_of_remote_workspaces,
            number_of_booked_workspaces,
          }) => ({
            id: String(id),
            workspacesCount: this.officesNormalization.normalizeWorkspacesCountFromApi(
              number_of_all_workspaces,
              number_of_free_workspaces,
              number_of_reserved_workspaces,
              number_of_booked_workspaces,
              number_of_occupied_workspaces,
              number_of_remote_workspaces,
            ),
          }),
        ),
      ),
    );
  }

  public getDailyAttendance({startDate, endDate, officeId, language}: OfficeStatisticsDailyAttendanceRequest) {
    const query = new URLSearchParams();
    const locale = getLocaleByLanguage(language);

    if (startDate && endDate) {
      const formattedStartDate = format(new Date(startDate), 'yyyy-MM-dd', {locale});
      const formattedEndDate = format(new Date(endDate), 'yyyy-MM-dd', {locale});

      query.set('date_range', `${formattedStartDate},${formattedEndDate}`);
    }

    return this.http
      .GET<Array<OfficeStatisticsDailyAttendanceResponse>>(
        `/offices/${officeId}/office_statistics_daily/${query.toString() ? `?${query.toString()}` : ''}`,
      )
      .pipe(
        map((list) =>
          (list || []).map((listItem) => {
            const localizedDate = listItem?.date ? format(new Date(listItem.date), 'dd.MM.yyyy', {locale}) : null;

            return {
              date: localizedDate,
              maxOccupancyPercentage: listItem?.max_occupancy_percentage || 0,
              occupiedWorkspaces: listItem?.office_occupied_workspaces || 0,
              totalWorkspaces: listItem?.office_total_workspaces || 0,
              uniqueFaceIdCount: listItem?.unique_visitors_count || 0,
            };
          }),
        ),
      );
  }

  public exportDailyAttendance({startDate, endDate, officeId, language}: OfficeStatisticsDailyAttendanceRequest) {
    const query = new URLSearchParams();
    const locale = getLocaleByLanguage(language);

    query.set('export_to_excel', 'true');

    if (startDate && endDate) {
      const formattedStartDate = format(new Date(startDate), 'yyyy-MM-dd', {locale});
      const formattedEndDate = format(new Date(endDate), 'yyyy-MM-dd', {locale});
      query.set('date_range', `${formattedStartDate},${formattedEndDate}`);
    }

    return this.http
      .GET(`/offices/${officeId}/office_statistics_daily/${query.toString() ? `?${query.toString()}` : ''}`, {
        responseType: 'arraybuffer',
      })
      .pipe(
        map((file: File) => {
          const link = document.createElement('a');
          link.setAttribute('download', `report-daily-statistics-${officeId}.xlsx`);

          const data = new Blob([file], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          link.href = URL.createObjectURL(data);
          link.click();

          URL.revokeObjectURL(link.href);
        }),
      );
  }

  public getMonthlyAttendance({year, language, officeId}: OfficeStatisticsMonthlyAttendanceRequest) {
    const query = new URLSearchParams();
    const locale = getLocaleByLanguage(language);

    if (year) {
      query.set('year', year);
    }

    return this.http
      .GET<Array<OfficeStatisticsMonthlyAttendanceResponse>>(
        `/offices/${officeId}/office_analytics_monthly/${query.toString() ? `?${query.toString()}` : ''}`,
      )
      .pipe(
        map((list) =>
          (list || []).map((listItem) => {
            const localizedMonthName = listItem?.month ? format(new Date(listItem.month), 'LLLL', {locale}) : null;

            return {
              month: localizedMonthName ? capitalize(localizedMonthName) : null,
              year: listItem?.month ? format(new Date(listItem.month), 'yyyy') : null,
              averageOccupancyPercentage: listItem?.average_occupancy_percentage || 0,
              averageOccupiedWorkspaces: listItem?.average_occupied_workspaces || 0,
              averageTotalWorkspaces: listItem?.average_total_workspaces || 0,
              averageVisitorsCount: listItem?.average_visitors_count || 0,
            };
          }),
        ),
      );
  }

  public getOfficeStatisticsAvailableYears({officeId}: OfficeStatisticsAvailableYearsRequest) {
    const query = new URLSearchParams();
    if (officeId) {
      query.set('office_id', officeId);
    }
    return this.http.GET<OfficeStatisticsAvailableYearsResponse>(
      `/offices/office_statistics_available_years/${query.toString() ? `?${query.toString()}` : ''}`,
    );
  }
}

export default OfficesApiService;
