import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Icon} from '@innowise-group/ui-kit';
import {BookedStatus, Employee, RequestStatus, WorkspaceBookType} from '@innowise-group/core';
import {getIconByRequestType} from '@innowise-group/utilities';
import * as Styled from './workspace-view.styles';
import WorkspaceBookingInfo from './workspace-booking-info.component';
import WorkspaceEmployeesList from './workspace-employees-list.component';
import {FloorData} from '../../pages/floor-details/use-plan-details.hook';
import {FloorContext} from '../../components/floor-plan/floor-plan.component';

interface WorkspaceInfoProps {
  status: WorkspaceBookType | RequestStatus;
  employee: Employee;
  color: string;
  range: {
    startDay: string;
    endDay: string;
  };
  bookedStatus?: BookedStatus[];
  activeRequests?: BookedStatus[];
  onBookWorkspaceClick: () => void;
  showOnPlan?: boolean;
  comment?: string;
  floor?: FloorData;
}

const isFree = (workspace: WorkspaceInfoProps) => workspace.status === 'Free' && !workspace.activeRequests.length;

const hasTemporaryRequest = (workspace: WorkspaceInfoProps) => {
  const hasActiveRequest = (workspace) => !!(workspace?.activeRequests || []).length;
  const isTemporaryRequest = (request: BookedStatus) => request.range.startDay && request.range.endDay;
  return hasActiveRequest(workspace) && isTemporaryRequest(workspace.activeRequests[0]);
};

const isTemporaryBooked = (workspace: WorkspaceInfoProps) => workspace.status === 'Booked';

const WorkspaceInfo: React.FC<WorkspaceInfoProps> = (workspace) => {
  const {t} = useTranslation();

  const {
    status,
    employee,
    color,
    range,
    bookedStatus = [],
    activeRequests = [],
    onBookWorkspaceClick,
    showOnPlan = false,
    comment,
    floor,
  } = workspace;

  const statusText =
    status === 'delayed' ? 'canceled' : status === 'conflict' ? 'in-clarification' : status?.toLowerCase();

  const {floorsEventEmitter} = useContext(FloorContext);

  return (
    <Styled.WorkspaceInfoContainer>
      {activeRequests.length > 0 && <WorkspaceEmployeesList workspaces={activeRequests} status="in-clarification" />}
      {bookedStatus.length > 0 && <WorkspaceEmployeesList workspaces={bookedStatus} status="Booked" />}
      {!bookedStatus.length && !activeRequests.length && (
        <>
          <Styled.WorkspaceStatusBlock>
            <Icon type={getIconByRequestType(status)} size={16} fill="white" background={color} />
            <Styled.WorkspaceStatusText type="h3" textColor={color}>
              {t(`statuses.${statusText}`)}
            </Styled.WorkspaceStatusText>
          </Styled.WorkspaceStatusBlock>
          {status !== 'Free' && status !== 'Booked' && (
            <WorkspaceBookingInfo employee={employee} range={range} comment={comment} />
          )}
        </>
      )}
      {(isFree(workspace) || hasTemporaryRequest(workspace) || isTemporaryBooked(workspace)) && !showOnPlan && (
        <Styled.BookButton
          disabled={!floor.floor || !floorsEventEmitter.isClientReady}
          onClick={onBookWorkspaceClick}
          icon="u_bookmark"
          iconSize={16}
          withLoader={!floorsEventEmitter.isClientReady}
        >
          {t('buttons.book')}
        </Styled.BookButton>
      )}
    </Styled.WorkspaceInfoContainer>
  );
};

export default React.memo(WorkspaceInfo);
