import {Select, SelectProps} from '@innowise-group/ui-kit';
import React, {memo, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {AvailableRoomsAndWorkspaces} from '@innowise-group/core';

interface WorkspacesSelectProps extends SelectProps {
  roomId?: string;
  startDay?: string;
  endDay?: string;
  idEdit?: boolean;
  editRequestId?: string;
  roomsList?: AvailableRoomsAndWorkspaces[];
}

const WorkspacesSelect: React.FC<WorkspacesSelectProps> = ({
  roomId,
  roomsList,
  defaultValue,
  startDay,
  endDay,
  value,
  onValueChange,
  editRequestId,
  ...props
}) => {
  const {t} = useTranslation();

  const workspaces = useMemo(() => {
    const room = roomsList.find((room) => roomId === room.id);
    return roomsList.length && roomId && room
      ? room.workspaces
          .map((room) => ({value: room.id, title: room.number}))
          .sort((roomCurrent, roomNext) => Number(roomCurrent.title) - Number(roomNext.title))
      : [];
  }, [roomId, roomsList]);

  useEffect(() => {
    const isValueExistInAvailable = workspaces.find((workspace) => workspace.value === value);
    if (value && !isValueExistInAvailable && roomsList.length) {
      onValueChange(null);
    }
  }, [workspaces, value, onValueChange, roomsList]);

  const transformValue = (id: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (id) {
        const workspaceById = workspaces.find((workspace) => workspace.value === id);
        if (workspaceById) {
          resolve(workspaceById.title);
        } else {
          resolve('');
        }
      } else {
        resolve('');
      }
    });
  };

  return (
    <Select
      options={workspaces}
      asyncValueTransformer={transformValue}
      placeholder={t('placeholders.choseSeat')}
      onValueChange={onValueChange}
      defaultValue={defaultValue}
      value={value}
      {...props}
    />
  );
};

export default memo(WorkspacesSelect);
