import {Tab, TabPanel} from '@innowise-group/ui-kit';
import {SyntheticEvent, useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getCurrentMonthStartEndDate} from '@shared/utilities/date';

import AttendanceStatisticsDaily from './attendance-statistics-daily.component';
import AttendanceStatisticsMonthly from './attendance-statistics-monthly.component';
import {OfficeDetailsAttendance} from '../../services/office-details-attendance.context';
import * as Styled from './styles';

export const DAILY_TAB = 'daily';
export const MONTHLY_TAB = 'monthly';

export type AttendanceStatisticsTabs = typeof DAILY_TAB | typeof MONTHLY_TAB;

const AttendanceStatisticsContainer = () => {
  const {t} = useTranslation();
  const {requestDailyAttendance, requestMonthlyAttendance, getAvailableYearsStatistics} =
    useContext(OfficeDetailsAttendance);
  const [activeTab, setActiveTab] = useState<AttendanceStatisticsTabs>(() => {
    /** Avoiding react-router-dom usage according to the bug: https://github.com/remix-run/react-router/discussions/9851 */
    const searchParams = new URLSearchParams(window.location.search);

    return (searchParams.get('statistics-tab') as AttendanceStatisticsTabs) || DAILY_TAB;
  });
  const [dateRange, setDateRange] = useState<Date[]>(() => getCurrentMonthStartEndDate());

  const handleTabChange = useCallback((event: SyntheticEvent<HTMLLIElement>) => {
    const {value} = event.currentTarget.dataset;

    setActiveTab(value as AttendanceStatisticsTabs);
  }, []);

  useEffect(() => {
    getAvailableYearsStatistics();
    requestMonthlyAttendance();
    requestDailyAttendance(...dateRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestMonthlyAttendance, getAvailableYearsStatistics, requestDailyAttendance]);

  const handleDateChange = (dateRange: Date[]) => {
    setDateRange(dateRange);
  };

  return (
    <div>
      <Styled.StaticTabs value={activeTab} noScroll>
        <Tab
          value={DAILY_TAB}
          label={t('pages.offices.attendance.daily')}
          path={`?statistics-tab=${DAILY_TAB}`}
          disableForceRedirect
          onClick={handleTabChange}
        ></Tab>
        <Tab
          value={MONTHLY_TAB}
          label={t('pages.offices.attendance.monthly')}
          path={`?statistics-tab=${MONTHLY_TAB}`}
          disableForceRedirect
          onClick={handleTabChange}
        ></Tab>
      </Styled.StaticTabs>
      <TabPanel selectedValue={activeTab} value={DAILY_TAB}>
        <AttendanceStatisticsDaily handleDateChange={handleDateChange} dateRange={dateRange} />
      </TabPanel>
      <TabPanel selectedValue={activeTab} value={MONTHLY_TAB}>
        <AttendanceStatisticsMonthly />
      </TabPanel>
    </div>
  );
};

export default AttendanceStatisticsContainer;
