import {styled, TableHead, Typography} from '@innowise-group/ui-kit';
import {Container, TableContainer as Table, TableRowContainer} from '@shared/ui-kit/table/table.styles';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const GeotrackTableHead = styled(TableHead)`
  font-size: 12px;
  font-weight: bold;
  color: ${({theme}) => theme.palette.generalAlt.font.secondary};
  background: ${({theme}) => theme.palette.generalAlt.background.secondary};

  @media (max-width: 481px) {
    font-size: 10px;
  }
`;

export const GeotrackTypography = styled(Typography)<{bold?: boolean; noBreak?: boolean}>`
  font-size: 12px;
  font-weight: ${({bold}) => (bold ? 'bold' : 'normal')};
  white-space: ${({noBreak}) => (noBreak ? 'nowrap' : 'unset')};
  color: ${({theme}) => theme.palette.generalAlt.font.secondary};

  @media (max-width: 481px) {
    font-size: 10px;
  }
`;

export const EmployeeNameTypography = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  line-height: 24.59px;
`;

export const GeotrackInfoContainer = styled.div`
  border: 1px solid ${({theme}) => theme.palette.generalAlt.border.secondary};
  margin-top: 10px;
  font-size: 10px !important;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  & ${Container} {
    overflow: scroll;
    height: 100%;
    max-height: 490px;

    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-corner {
      background: ${({theme}) => theme.palette.generalAlt.scrollbar.track};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: ${({theme}) => theme.palette.generalAlt.scrollbar.thumb};
      border: 2px solid ${({theme}) => theme.palette.generalAlt.scrollbar.track};
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${({theme}) => theme.palette.general.lightGrey[80]};
    }

    @supports not selector(::-webkit-scrollbar) {
      scrollbar-width: thin;
      scrollbar-color: ${({theme}) => theme.palette.generalAlt.scrollbar.track};
    }
  }

  & ${Table} {
    border: none;
    border-radius: 0;
    overflow-y: scroll;
    height: 100%;
    & > thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }
    & th,
    td {
      vertical-align: top;
      padding: 16px;
      &:first-child {
        padding: 16px 0px 16px 32px;
      }
    }
    & th {
      border-bottom: 1px solid ${({theme}) => theme.palette.generalAlt.border.tertiary};
    }
  }

  & ${TableRowContainer} {
    &:nth-child(1n) {
      background-color: ${({theme}) => theme.palette.generalAlt.rows.odd};
    }
    &:nth-child(2n) {
      background-color: ${({theme}) => theme.palette.generalAlt.rows.even};
    }
    line-height: 1.15rem;
  }
`;
