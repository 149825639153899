import {SyntheticEvent, useMemo} from 'react';

import * as Styled from './tabs.styles';
import {FadeInOutView} from '../fade-in-out-view';
import {Typography} from '../typography';

export interface TabProps extends React.HTMLAttributes<HTMLLIElement> {
  label?: string;
  value?: unknown;
  tabsvalue?: unknown;
  path?: string;
  /** Bug: https://github.com/remix-run/react-router/discussions/9851 */
  disableForceRedirect?: boolean;
}

type LinkParamsProps = {
  to: string;
  onClick?: (event: SyntheticEvent) => void;
};

const Tab: React.FC<TabProps> = ({label, tabsvalue, value, path = '#', disableForceRedirect, ...props}) => {
  /** Bug: https://github.com/remix-run/react-router/discussions/9851 */
  const linkParams = useMemo(() => {
    const params: LinkParamsProps = {to: path};

    if (disableForceRedirect) {
      params.onClick = (event) => {
        event.preventDefault();

        window.history.pushState({}, '', path);
      };
    }

    return params;
  }, [disableForceRedirect, path]);

  const selected = tabsvalue === value;
  return (
    <Styled.TabContainer {...props} selected={selected} data-value={value}>
      <Styled.NavigationLink {...linkParams}>
        <Typography>{label}</Typography>
      </Styled.NavigationLink>
      {selected ? (
        <FadeInOutView>
          <Styled.TabSelectionLine />
        </FadeInOutView>
      ) : null}
    </Styled.TabContainer>
  );
};

export default Tab;
