import {Select, SelectProps} from '@innowise-group/ui-kit';
import React, {memo, useEffect, useState} from 'react';
import {AvailableRoomsAndWorkspaces} from '@innowise-group/core';
import {useTranslation} from 'react-i18next';

interface RoomsSelectProps extends SelectProps {
  floorsId?: string;
  roomsList?: AvailableRoomsAndWorkspaces[];
  value: string;
}

const RoomsSelect: React.FC<RoomsSelectProps> = ({roomsList, value, defaultValue, ...props}) => {
  const [rooms, setRoom] = useState<{value: string; title: string}[]>([]);
  const {t} = useTranslation();

  useEffect(() => {
    if (roomsList.length) {
      setRoom(
        roomsList
          .filter((room) => room.roomType !== 'Water closet' && room.isOur)
          .filter((room) => room.roomType !== 'Hall' && room.isOur)
          .map((room) => ({value: room.id, title: room.number})),
      );
    } else {
      setRoom([]);
    }
  }, [roomsList]);

  const transformValue = (id: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (id) {
        const roomId = value ?? defaultValue;
        const roomById = roomsList.find((room) => (id ? room.id === id : room.id === roomId));
        if (roomById) {
          resolve(roomById.number);
        } else {
          resolve('');
        }
      } else {
        resolve('');
      }
    });
  };

  return (
    <Select
      options={rooms}
      asyncValueTransformer={transformValue}
      placeholder={t('placeholders.choseRoom')}
      defaultValue={defaultValue}
      value={value}
      {...props}
    />
  );
};

export default memo(RoomsSelect);
