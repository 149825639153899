import {getItemById} from '@shared/utilities';
import {BookingWorkspaceFormFields, Range, WorkspacesBookingList} from './booking-workspace-form.component';
import {areIntervalsOverlapping, format} from 'date-fns';

export const checkBookingDatesCollisions = (
  seat: BookingWorkspaceFormFields.Seat,
  list: WorkspacesBookingList,
  selectedStartDate: Date,
  selectedEndDate: Date,
  employeeId: string,
): boolean => {
  const selectedRange = {start: selectedStartDate, end: selectedEndDate};

  if (list?.length && selectedEndDate) {
    const selectedWorkspaceInfo = getItemById(list, seat);

    return selectedWorkspaceInfo?.excludeDates.some(
      (bookingStatus) =>
        areIntervalsOverlapping(selectedRange, bookingStatus.range) && bookingStatus.employeeId !== employeeId,
    );
  } else {
    return false;
  }
};

export const getDatesToExclude = (
  list: WorkspacesBookingList,
  seat: string,
  editRequestId?: string,
  startDay?: string,
  endDay?: string,
): Range[] => {
  const currentWorkspace = getItemById(list, seat);

  if (editRequestId) {
    return currentWorkspace?.excludeDates
      .filter((status) => {
        return (
          format(status.range.start, 'yy-MM-dd') !== format(new Date(startDay), 'yy-MM-dd') &&
          format(status.range.end, 'yy-MM-dd') !== format(new Date(endDay), 'yy-MM-dd')
        );
      })
      .map((status) => ({
        start: new Date(status.range.start),
        end: new Date(status.range.end),
      }));
  }
  const {excludeDates = [], activeDates = []} = currentWorkspace ?? {};

  return [...excludeDates, ...activeDates].map((status) => ({
    start: new Date(status.range.start),
    end: new Date(status.range.end),
  }));
};

export function findFirstAvailableDate(periods: Range[]): Date {
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  if (periods.length) {
    for (const period of periods) {
      let startDate = new Date(period.start);
      let endDate = new Date(period.end);

      if (currentDate >= startDate && currentDate <= endDate) {
        currentDate = endDate;
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }
  }
  return currentDate;
}
