import {Paper} from '@innowise-group/ui-kit';
import {styled} from '@innowise-group/ui-kit';

import {StatusesLegend} from '../../components/statuses-legend';
import {TitleContainer} from '../../../../shared/ui-kit/paper/paper.styles';

export const FloorDetailsContainer = styled(Paper)`
  height: 100%;
  position: relative;

  ${TitleContainer} {
    min-height: auto;
    padding: 15px 20px 10px 20px;
  }
`;

export const FloorTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
`;

export const FloorTitleRow = styled.div`
  display: flex;
  gap: 32px;
  row-gap: 16px;
  align-items: center;

  &.space-between {
    justify-content: space-between;
  }

  &.float {
    flex-wrap: wrap;
  }

  @media (max-width: 769px) {
    & label {
      display: flex;
      justify-content: center;
      line-height: 40px;
      font-size: 13px;

      & > div {
        width: 17px;
        height: 17px;
        line-height: 0;
      }
    }
    & > div > div:nth-last-child(-n + 2) {
      display: none;
    }
  }
  @media (max-width: 481px) {
    & label {
      font-size: 12px;
      line-height: 22px;

      & > div {
        width: 15px;
        height: 15px;
      }
    }
  }
`;

export const SearchFloorFormContainer = styled.div`
  & form > div {
    flex-wrap: nowrap;
  }
`;

export const SearchEmployeeContainer = styled(SearchFloorFormContainer)``;

export const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  overflow-x: hidden;
  flex: 1;
  justify-content: flex-end;
  min-width: 160px;

  @media (max-width: 993px) {
    display: none;
    margin-top: 0;
  }
`;

export const BookingWorkspaceFormContainer = styled(SearchEmployeeContainer)`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const PlanContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 0 0 6px 6px;
  min-height: 0;
  position: relative;
`;

export const Legend = styled(StatusesLegend)`
  position: absolute;
  right: 24px;
  bottom: 24px;

  & > div > div {
    background: ${({theme}) => theme.palette.general.paper};
    border-radius: 10px;
  }
`;

export const SecondPlanContainer = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;
