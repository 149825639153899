import {Typography} from '../typography';
import * as Styled from './modal.styles';
import {Icon} from '../icon';

export interface ModalHeaderProps {
  title: string;
  hideBottomBorder?: boolean;
  onClose?: () => void;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({title, onClose, children, hideBottomBorder}) => (
  <Styled.ModalHeader hideBottomBorder={hideBottomBorder}>
    {title ? <Typography type="h3">{title}</Typography> : children}
    <Icon type="u_multiply" onClick={onClose} isPointer isHover />
  </Styled.ModalHeader>
);

export default ModalHeader;
