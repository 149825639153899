import {
  ABSENT,
  CONFIRMED_VISIT,
  NOT_DEFINED,
  REMOTE_WORK,
  REMOTE_WORK_WITH_VISIT,
  UNCONFIRMED_VISIT,
} from '../../components/stacked-chart/utils';

const OFFENDER = 'offender';
const LATECOMER = 'latecomer';

export const classNames = {
  [CONFIRMED_VISIT]: 'confirmedVisit',
  [UNCONFIRMED_VISIT]: 'office',
  [REMOTE_WORK]: 'remoteWork',
  [REMOTE_WORK_WITH_VISIT]: 'remoteWorkWithVisit',
  [ABSENT]: 'absent',
  [OFFENDER]: 'offender',
  [LATECOMER]: 'latecomer',
  [NOT_DEFINED]: 'notDefined',
};

export const daysLegend = {
  confirmedVisit: 'confirmedOffice',
  unconfirmedVisit: 'office',
  remoteWork: 'remote',
  remoteWorkWithVisit: ['confirmedOffice', 'remote'],
  absent: 'outOfOffice',
  delay: 'delay',
  violation_of_work_format: 'violation_of_work_format',
};
