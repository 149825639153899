import {Workspace} from '@shared/core';
import {endOfDay, parse, startOfDay} from 'date-fns';
import {useMemo} from 'react';

interface BookingRange {
  employeeId: string;
  end: Date;
  id: string;
  isOwnedByCurrentUser: boolean;
  start: Date;
  workspaceNumber: string;
}

interface UseCalculatedBookingRangesProps {
  associatedWorkspace: Workspace;
  currentUserId: string;
  includeCurrentUserRequests?: boolean;
}

const API_DATE_FORMAT = 'dd.MM.yyyy';

const useCalculatedBookingRanges = ({
  associatedWorkspace,
  currentUserId,
  includeCurrentUserRequests,
}: UseCalculatedBookingRangesProps) => {
  const disabledForBookingRanges = useMemo((): Array<BookingRange> => {
    const alreadyBookedRanges = (associatedWorkspace?.bookedStatus || [])
      .map((booking) => {
        if (booking?.range?.startDay && booking?.range?.endDay) {
          const isOwnedByCurrentUser = currentUserId === booking.employee?.id;

          if (!includeCurrentUserRequests && isOwnedByCurrentUser) {
            return null;
          }

          return {
            employeeId: booking.employee?.id,
            employeeName: booking.employee?.email,
            end: endOfDay(parse(booking.range.endDay, API_DATE_FORMAT, new Date())),
            id: booking.id,
            isOwnedByCurrentUser: isOwnedByCurrentUser,
            start: startOfDay(parse(booking.range.startDay, API_DATE_FORMAT, new Date())),
            workspaceNumber: booking.number,
          };
        }

        return null;
      })
      .filter(Boolean);

    const activeRequestsRanges = (associatedWorkspace?.activeRequests || [])
      .map((request) => {
        if (request?.range?.startDay && request?.range?.endDay) {
          const isOwnedByCurrentUser = currentUserId === request.employee?.id;

          if (!includeCurrentUserRequests && isOwnedByCurrentUser) {
            return null;
          }

          return {
            employeeId: request.employee?.id,
            employeeName: request.employee?.email,
            end: endOfDay(parse(request.range.endDay, API_DATE_FORMAT, new Date())),
            id: request.id,
            isOwnedByCurrentUser: isOwnedByCurrentUser,
            start: startOfDay(parse(request.range.startDay, API_DATE_FORMAT, new Date())),
            workspaceNumber: request.number,
          };
        }

        return null;
      })
      .filter(Boolean);

    return [].concat(alreadyBookedRanges).concat(activeRequestsRanges);
  }, [associatedWorkspace, currentUserId, includeCurrentUserRequests]);

  return disabledForBookingRanges;
};

export default useCalculatedBookingRanges;
