import {enUS, ru} from 'date-fns/esm/locale';

const getLanguageFromLS = () => {
  return localStorage.getItem('htlgt-language') || 'ru';
};

export const getLocaleByLanguage = (language?: string) => {
  switch (language) {
    case 'en':
      return enUS;
    case 'ru':
    default:
      return ru;
  }
};

export default getLanguageFromLS;
