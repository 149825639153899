import {combineLatest, concatMap, finalize, forkJoin, switchMap, tap} from 'rxjs';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Floor,
  OfficeDetails,
  OfficesApiService,
  useService,
  FloorsApiService,
  EditFloorManagersPayload,
  ManagerTypes,
  LanguageService,
  OfficeStatisticsAvailableYearsResponse,
} from '@innowise-group/core';

import {OfficesModalsFacadeService} from '../../../services/offices-modals-facade';
import {AddOfficeFormValues} from '../../../components/add-office-form';

export interface UseOfficeResult {
  isLoading: boolean;
  officeData: OfficeDetails;
  floors: Floor[];
  shortStatistics: {[key: string]: number};
  handleEditLocation: () => void;
  handleEditFloors: () => void;
  handleEditFloorManagers: (
    id: string,
  ) => (
    managers: EditFloorManagersPayload,
    diffOfficeManagers: (
      officeManagers: AddOfficeFormValues['managers'],
      floorsManagers: AddOfficeFormValues['managers'],
    ) => AddOfficeFormValues['managers'],
    onSuccess: () => void,
    onComplete: () => void,
  ) => void;
  handleToggleVirtualFloor: (
    id: string,
  ) => (isVirtual: boolean, setIsVirtualHandler: (isVirtual: boolean) => void, isVirtualFloorExists: boolean) => void;
}

const getAddOfficeInitialValuesFromOfficeDetails = (
  {address, addressEng, addressRu, postcode, locationId, managers, jiraOfficeId}: OfficeDetails,
  floors: Floor[],
): AddOfficeFormValues => {
  return {
    address: addressRu,
    address_eng: addressEng,
    index: postcode,
    floors: floors.map((floor) => ({number: floor.number, isVirtual: floor.is_virtual})),
    location: locationId,
    managers:
      managers?.flatMap(({id, managerType}) => managerType.map((type) => ({office_manager: id, manager_type: type}))) ??
      [],
    jira_office_id: jiraOfficeId,
  };
};

export const useOffice = (id: string): UseOfficeResult => {
  const officesApi = useService(OfficesApiService);
  const floorsApi = useService(FloorsApiService);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [office, setOffice] = useState<OfficeDetails>(null);
  const [floors, setFloors] = useState<Floor[]>([]);
  const [shortStatistics, setShortStatistics] = useState<{[key: string]: number}>({});
  const officesModalsFacade = useService(OfficesModalsFacadeService);

  const {t} = useTranslation();

  useEffect(() => {
    const subscriber = combineLatest([
      officesApi.getOfficeById(id),
      officesApi.getOfficeFloors(id),
      officesApi.getOfficeShortStatistics(id),
    ]).subscribe(([office, floors, shortStatistics]) => {
      setOffice(office);
      setFloors(floors);
      setShortStatistics(shortStatistics);
      setIsLoading(false);
    });
    return () => subscriber.unsubscribe();
  }, [officesApi, id]);

  return {
    isLoading,
    officeData: office,
    floors: floors || [],
    shortStatistics: shortStatistics || {},
    handleEditFloors: () => {
      const addOfficeInitialValues = getAddOfficeInitialValuesFromOfficeDetails(office, floors);

      officesModalsFacade.openAddOfficeModal(
        (values: AddOfficeFormValues) => {
          return officesApi
            .editOffice(id, {
              ...addOfficeInitialValues,
              ...values,
            })
            .pipe(
              switchMap(() => officesApi.getOfficeFloors(id)),
              tap(setFloors),
            );
        },
        {
          floors: addOfficeInitialValues.floors,
        },
        {
          title: t('modals.changeFloors'),
          valuesToShow: ['floor'],
        },
      );
    },
    handleEditLocation: () => {
      officesModalsFacade.openAddOfficeModal(
        (values: AddOfficeFormValues) => {
          return officesApi
            .editOffice(id, {
              ...getAddOfficeInitialValuesFromOfficeDetails(office, floors),
              ...values,
            })
            .pipe(
              switchMap(() => officesApi.getOfficeById(id)),
              tap(setOffice),
            );
        },
        {
          address: office.addressRu,
          address_eng: office.addressEng,
          location: office.locationId,
          index: office.postcode,
          jira_office_id: office.jiraOfficeId,
        },
        {
          title: t('modals.changeLocation'),
          valuesToShow: ['location'],
        },
      );
    },
    handleEditFloorManagers: (floor_id: string) => {
      return (
        managers: EditFloorManagersPayload,
        diffOfficeManagers: (
          officeManagers: AddOfficeFormValues['managers'],
          floorsManagers: AddOfficeFormValues['managers'],
        ) => AddOfficeFormValues['managers'],
        onSuccess: () => void,
        onComplete: () => void,
      ) => {
        const addOfficeInitialValues = getAddOfficeInitialValuesFromOfficeDetails(office, floors);

        return floorsApi
          .editFloorManagers(floor_id, managers)
          .pipe(
            concatMap((response) =>
              officesApi.editOffice(
                id,
                {
                  ...addOfficeInitialValues,
                  managers: diffOfficeManagers(
                    addOfficeInitialValues.managers,
                    floors
                      .filter((floor) => floor.number !== response.updated_floors[0].number)
                      .flatMap(({manager1, manager2, hr}) =>
                        [
                          manager1.id && {office_manager: manager1.id, manager_type: ManagerTypes.OfficeManager},
                          manager2.id && {office_manager: manager2.id, manager_type: ManagerTypes.OfficeManager},
                          hr.id && {office_manager: hr.id, manager_type: ManagerTypes.HrManager},
                        ].filter(Boolean),
                      ),
                  ),
                },
                true,
              ),
            ),
            switchMap(() => forkJoin([officesApi.getOfficeFloors(id), officesApi.getOfficeById(id)])),
            tap(([floors, office]) => {
              setFloors(floors);
              setOffice(office);
            }),
            finalize(onComplete),
          )
          .subscribe(onSuccess);
      };
    },
    handleToggleVirtualFloor: (id) => (isVirtual, setIsVirtualHandler, isVirtualFloorExists) => {
      if (isVirtualFloorExists && isVirtual) {
        officesModalsFacade.openInfoModal(t(`modals.oneVirtualFloorMessage`), t(`modals.attention`), t(`buttons.ok`));
      } else {
        officesModalsFacade
          .openToggleVirtualFloorModal(
            t('modals.toggleVirtualFloorTitle'),
            t('modals.toggleVirtualFloorText'),
            t('buttons.ok'),
            t('buttons.cancel'),
          )
          .pipe(switchMap(() => floorsApi.editFloorType(id, {is_virtual: isVirtual})))
          .subscribe({
            next() {
              setIsVirtualHandler(isVirtual);
              setFloors((floors) =>
                floors.map((floor) => (floor.id === id ? {...floor, is_virtual: isVirtual} : floor)),
              );
            },
            error() {
              setIsVirtualHandler(!isVirtual);
            },
          });
      }
    },
  };
};

export const useDailyAttendance = (officeId: string) => {
  const [dailyAttendance, setDailyAttendance] = useState<any>([]);
  const {getAppLanguage} = useService(LanguageService);

  const [isLoading, setLoading] = useState(false);

  const officesApi = useService(OfficesApiService);
  const language = getAppLanguage();

  const requestDailyAttendance = useCallback(
    (startDate?: string, endDate?: string) => {
      setLoading(true);

      officesApi.getDailyAttendance({officeId, startDate, endDate, language}).subscribe((data) => {
        setDailyAttendance(data);
        setLoading(false);
      });
    },
    [language, officeId, officesApi],
  );

  return {
    dailyAttendance,
    isDailyAttendanceLoading: isLoading,
    requestDailyAttendance,
  };
};

export const useMonthlyAttendance = (officeId: string) => {
  const [monthlyAttendance, setMonthlyAttendance] = useState<any>([]);
  const {getAppLanguage} = useService(LanguageService);

  const [isLoading, setLoading] = useState(false);

  const officesApi = useService(OfficesApiService);
  const language = getAppLanguage();

  const requestMonthlyAttendance = useCallback(
    (year?: string) => {
      setLoading(true);

      officesApi.getMonthlyAttendance({officeId, year, language}).subscribe((data) => {
        setMonthlyAttendance(data);
        setLoading(false);
      });
    },
    [language, officeId, officesApi],
  );

  return {
    monthlyAttendance,
    isMonthlyAttendanceLoading: isLoading,
    requestMonthlyAttendance,
  };
};

export const useExportDailyAttendance = (officeId: string) => {
  const [isLoading, setLoading] = useState(false);
  const officesApi = useService(OfficesApiService);

  const requestExport = useCallback(
    (startDate?: string | Date, endDate?: string | Date) => {
      setLoading(true);

      officesApi.exportDailyAttendance({officeId, startDate, endDate}).subscribe((data) => {
        setLoading(false);
      });
    },
    [officeId, officesApi],
  );

  return {
    isLoading,
    requestExport,
  };
};

export const useAvailableYearsStatistics = (officeId: string) => {
  const [availableYearsStatistics, setAvailableYearsStatistics] = useState<OfficeStatisticsAvailableYearsResponse>([]);
  const [isLoading, setLoading] = useState(false);
  const officesApi = useService(OfficesApiService);

  const getAvailableYearsStatistics = useCallback(() => {
    setLoading(true);
    officesApi.getOfficeStatisticsAvailableYears({officeId}).subscribe((data) => {
      setAvailableYearsStatistics(data);
      setLoading(false);
    });
  }, [officeId, officesApi]);
  return {
    isAvailableYearsLoading: isLoading,
    availableYearsStatistics,
    getAvailableYearsStatistics,
  };
};
