import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {NodeComponentProps, useZoomPanHelper} from 'react-flow-renderer';
import {FadeInOutView, Tooltip, useTheme} from '@innowise-group/ui-kit';
import {RequestOnPlan, RoomType, useService, Workspace} from '@innowise-group/core';
import {getThemeByRequestType} from '@innowise-group/utilities';
import {useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';

import * as Styled from './workspace-view.styles';
import WorkspaceActions from './workspace-actions.component';
import WorkspaceInfo from './workspace-info.component';
import {FloorsModalsFacadeService} from '../../services/floors-modals-facade';
import {updateWorkspaceData} from '../../utilities/update-workspace-data.utility';
import {createEmptyElement} from '../../utilities/create-empty-element.utility';
import {FloorContext, ShowOnPlanRequest} from '../floor-plan/floor-plan.component';
import {useSearchParams} from 'react-router-dom';

export interface WorkspaceElementData extends Workspace {
  isViewMode: boolean;
  requestData?: RequestOnPlan;
}

const WorkspaceView: React.FC<NodeComponentProps<WorkspaceElementData>> = ({data, selected}) => {
  const {
    id,
    type,
    coordinates,
    employee,
    status,
    room,
    comment,
    range,
    isViewMode,
    number,
    height,
    width,
    bookedStatus,
    activeRequests,
    rotateDegree,
  } = data;
  const workspace = useMemo(() => {
    return {
      id,
      number,
      room,
      comment,
      range,
      coordinates,
      type,
      employee,
      status,
      bookedStatus,
      rotateDegree,
      width,
      height,
      activeRequests,
    };
  }, [
    bookedStatus,
    comment,
    coordinates,
    employee,
    height,
    id,
    number,
    range,
    room,
    rotateDegree,
    status,
    type,
    width,
    activeRequests,
  ]);
  const [offset, setOffset] = useState({x: 0, y: 0});
  const workspaceRef = useRef<HTMLDivElement>(null);
  const {setCenter} = useZoomPanHelper();
  const {floorsEventEmitter, floor} = useContext(FloorContext);
  const {focusWorkspace, ...requestData} = useContext(ShowOnPlanRequest);
  const {state} = useLocation() as {state: {workspaceId: string}};
  const [searchParams] = useSearchParams();

  const focusOnTable = useCallback(() => {
    const x = coordinates[0][0] + width / 2;
    const y = coordinates[0][1] + height / 2;
    const zoom = 1.05;
    setCenter(x, y, zoom, 800);
  }, [coordinates, width, height, setCenter]);

  const foundedWorkspace = useMemo(() => {
    return (
      state?.workspaceId === id ||
      requestData?.idWorkspace === id ||
      focusWorkspace?.id === id ||
      searchParams.get('focus_on') === id
    );
  }, [state, id, requestData, focusWorkspace, searchParams]);

  const {t} = useTranslation();
  const theme = useTheme();
  const color = getThemeByRequestType(status, theme);
  const floorsModalsFacade = useService(FloorsModalsFacadeService);

  useEffect(() => {
    if (workspaceRef && foundedWorkspace) {
      const time = setTimeout(() => {
        focusOnTable();
        workspaceRef.current.click();
      }, 0);
      return () => clearTimeout(time);
    }
  }, [workspaceRef, foundedWorkspace, focusOnTable]);

  useEffect(() => {
    if (!selected) {
      setOffset(() => ({x: 0, y: 0}));
    }
    return () => setOffset(() => ({x: 0, y: 0}));
  }, [selected]);

  const handleEditClick = useCallback(() => {
    floorsEventEmitter.emit('editWorkspace', workspace);
  }, [floorsEventEmitter, workspace]);

  const handleClockwiseRotateClick = useCallback(() => {
    const updatedWorkspace = updateWorkspaceData({...workspace, rotateDegree: rotateDegree + 15});
    floorsEventEmitter.emit('rotate_workspace', {workspace: updatedWorkspace});
  }, [floorsEventEmitter, rotateDegree, workspace]);

  const handleAntiClockwiseRotateClick = useCallback(() => {
    const updatedWorkspace = updateWorkspaceData({...workspace, rotateDegree: rotateDegree - 15});
    floorsEventEmitter.emit('rotate_workspace', {workspace: updatedWorkspace});
  }, [floorsEventEmitter, rotateDegree, workspace]);

  const handleDeleteClick = useCallback(() => {
    if (workspace.employee?.id) {
      floorsModalsFacade
        .openConfirmationModal(
          t('modals.deleteWorkspaceTitle', {number}),
          `${t('modals.deleteWorkspaceText', {number, room: room.number})} ${
            status === 'Occupied' || status === 'Remote'
              ? t('modals.deleteWorkspaceWarning')
              : status === 'Booked' || activeRequests.length > 0
              ? t('modals.deleteBookedWorkspaceText')
              : ''
          }`,
          t('modals.deleteWorkspaceConfirmationText'),
          t('buttons.cancel'),
        )
        .subscribe(() => {
          floorsEventEmitter.emit('delete', {id});
        });
    } else {
      floorsEventEmitter.emit('delete', {id});
    }
  }, [
    workspace.employee,
    floorsModalsFacade,
    t,
    number,
    room.number,
    status,
    activeRequests.length,
    floorsEventEmitter,
    id,
  ]);

  const handleBookWorkspaceClick = useCallback(() => {
    floorsEventEmitter.emit('bookWorkspace', id, room.id, bookedStatus);
  }, [bookedStatus, floorsEventEmitter, id, room]);

  const isVirtualFloor = useMemo(() => {
    return status === 'Remote';
  }, [status]);

  const handleCopyWorkspaceClick = useCallback(() => {
    const x = coordinates[0][0] + offset.x;
    const y = coordinates[0][1] + offset.y;
    const newWorkspace = createEmptyElement(isVirtualFloor, x, y, rotateDegree, room);
    const workspace = updateWorkspaceData(newWorkspace);
    floorsEventEmitter.emit('create', {workspace});
    setOffset((values) => ({x: values.x + 50, y: values.y + 25}));
  }, [coordinates, floorsEventEmitter, offset, room, rotateDegree, isVirtualFloor]);

  const copyWorkspaceIcon = useMemo(() => {
    return status === 'Free' || (status === 'Remote' && !employee.id);
  }, [status, employee.id]);

  const validateRoomWithoutBookingAccess = (roomType: RoomType) => {
    return roomType === 'Water closet' || roomType === 'Hall';
  };

  return (
    <Tooltip
      vertical="top"
      horizontal="center"
      active={selected}
      content={
        <FadeInOutView>
          {isViewMode ? (
            <WorkspaceInfo
              status={status}
              employee={employee}
              color={color.default}
              range={range}
              bookedStatus={bookedStatus.slice(0, 2)}
              activeRequests={activeRequests.slice(0, 2)}
              onBookWorkspaceClick={handleBookWorkspaceClick}
              showOnPlan={
                requestData?.showOnPlan || !!focusWorkspace || validateRoomWithoutBookingAccess(room.room_type)
              }
              comment={comment}
              floor={floor}
            />
          ) : (
            <WorkspaceActions
              onAntiClockwiseRotateClick={handleAntiClockwiseRotateClick}
              onClockwiseRotateClick={handleClockwiseRotateClick}
              onDeleteClick={handleDeleteClick}
              onEditClick={handleEditClick}
              onCopyWorkspaceClick={handleCopyWorkspaceClick}
              status={status}
              workspace={workspace}
              copyWorkspaceIcon={copyWorkspaceIcon}
            />
          )}
        </FadeInOutView>
      }
    >
      <Styled.WorkspaceViewContainer
        ref={workspaceRef}
        selected={selected}
        themeColor={activeRequests.length ? theme.palette.main : color}
        rotateDegree={rotateDegree}
        isViewMode={isViewMode}
        className={type}
      >
        <Styled.Wrapper>
          <Styled.Title>{number}</Styled.Title>
          {status !== 'Free' && employee?.fullName && <Styled.Subtitle>{employee?.fullName}</Styled.Subtitle>}
          {status === 'Booked' && bookedStatus?.length > 0 && (
            <Styled.Subtitle>{bookedStatus[0].employee?.fullName}</Styled.Subtitle>
          )}
        </Styled.Wrapper>
      </Styled.WorkspaceViewContainer>
    </Tooltip>
  );
};

export default React.memo(WorkspaceView);
