import {useEffect, useState} from 'react';

const getWindowDimensions = () => {
  return {
    documentWidth: document.documentElement.clientWidth,
    documentHeight: document.documentElement.clientHeight,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    documentWidth: document.documentElement.clientWidth,
    documentHeight: document.documentElement.clientHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  return windowDimensions;
};

export default useWindowDimensions;
