import {modalManager} from '@innowise-group/ui-kit';
import {ReleaseNotes} from '@shared-components';
import {useCallback, useEffect, useState} from 'react';
import {matchPath, useLocation} from 'react-router-dom';

import {AppRoutes} from '../constants';

interface UseReleaseNotesResult {
  modalsReadyCallback: () => void;
  open: () => void;
}

interface UseReleaseNotesProps {
  blackListPages?: Array<string>;
}

const DEFAULT_BLACK_LIST_PAGES = [AppRoutes.Auth, AppRoutes.Hrm, AppRoutes.Home];

export default function useReleaseNotes({
  blackListPages = DEFAULT_BLACK_LIST_PAGES,
}: UseReleaseNotesProps = {}): UseReleaseNotesResult {
  const [areModalsReady, setAreModalsReady] = useState(false);
  const {pathname} = useLocation();

  const modalsReadyCallback = useCallback(() => setAreModalsReady(true), []);
  const open = useCallback(
    () => modalManager.open(({close}) => <ReleaseNotes close={close} version={process.env.REACT_APP_VERSION} />),
    [],
  );

  useEffect(() => {
    if (!areModalsReady) return;

    const shownReleaseNotes: {[x: string]: boolean} = JSON.parse(localStorage.getItem('release_notes')) ?? {};
    const version = process.env.REACT_APP_VERSION;

    if (!(version in shownReleaseNotes)) {
      const isPatchBlackListed = blackListPages.some((blackListedPage) => matchPath(blackListedPage, pathname));

      if (!isPatchBlackListed) {
        open();

        shownReleaseNotes[version] = true;
        localStorage.setItem('release_notes', JSON.stringify(shownReleaseNotes));
      }
    }
  }, [areModalsReady, blackListPages, open, pathname]);

  return {
    modalsReadyCallback,
    open,
  };
}
