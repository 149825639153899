import {FC, createContext} from 'react';
import {useAvailableYearsStatistics, useDailyAttendance, useMonthlyAttendance} from '../pages/office-details/hooks';

export const OfficeDetailsAttendance = createContext(null);

export const OfficeDetailsAttendanceProvider: FC<{officeId: string}> = ({children, officeId}) => {
  const {dailyAttendance, isDailyAttendanceLoading, requestDailyAttendance} = useDailyAttendance(officeId);
  const {monthlyAttendance, isMonthlyAttendanceLoading, requestMonthlyAttendance} = useMonthlyAttendance(officeId);
  const {isAvailableYearsLoading, availableYearsStatistics, getAvailableYearsStatistics} =
    useAvailableYearsStatistics(officeId);

  return (
    <OfficeDetailsAttendance.Provider
      value={{
        officeId,
        dailyAttendance,
        monthlyAttendance,
        isDailyAttendanceLoading,
        isMonthlyAttendanceLoading,
        requestDailyAttendance,
        requestMonthlyAttendance,
        isAvailableYearsLoading,
        availableYearsStatistics,
        getAvailableYearsStatistics,
      }}
    >
      {children}
    </OfficeDetailsAttendance.Provider>
  );
};
