import {CustomDatePicker, Form, Loader, Table, TableData, TableRow, useTheme} from '@innowise-group/ui-kit';
import {FormProvider, Controller, useForm} from 'react-hook-form';

import {useCallback, useContext, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import * as Styled from './styles';
import {OfficeDetailsAttendance} from '../../services/office-details-attendance.context';
import {useExportDailyAttendance} from '../../pages/office-details/hooks';

interface AttendanceStatisticsDailyProps {
  dateRange: Date[];
  handleDateChange: (dateRange: Date[]) => void;
}

const AttendanceStatisticsDaily = ({handleDateChange, dateRange}: AttendanceStatisticsDailyProps) => {
  const {officeId, isDailyAttendanceLoading, dailyAttendance, requestDailyAttendance} =
    useContext(OfficeDetailsAttendance);

  const {isLoading: isExporting, requestExport} = useExportDailyAttendance(officeId);

  const {t} = useTranslation();
  const theme = useTheme();

  const form = useForm({
    defaultValues: {
      range: dateRange,
    },
  });
  const {watch} = form;

  useEffect(() => {
    const subscription = watch(({range}) => {
      const [startDate, endDate] = range;

      if ((startDate && endDate) || (!startDate && !endDate)) {
        requestDailyAttendance(startDate, endDate);
        handleDateChange(range);
      }
    });

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const handleExport = useCallback(() => {
    const {
      range: [startDate, endDate],
    } = form.getValues();

    requestExport(startDate, endDate);
  }, [requestExport, form]);

  const isNoResults = useMemo(() => {
    if (!isDailyAttendanceLoading && (dailyAttendance || []).length === 0) {
      return true;
    }

    return false;
  }, [dailyAttendance, isDailyAttendanceLoading]);

  return (
    <Styled.TabPanelContent>
      <Styled.ControlsBar>
        <FormProvider {...form}>
          <Form.Container name="attendance-statistics-mounthly">
            <Controller
              name="range"
              control={form?.control}
              defaultValue={dateRange}
              render={({field: {onChange, value}}) => {
                return (
                  <Form.Field>
                    <CustomDatePicker
                      Container={Styled.OverridenDatePickerContainer}
                      dateFormat="dd/MM/yyyy"
                      endDate={Array.isArray(value) && value.length > 0 ? value[1] : undefined}
                      isClearable
                      onChange={onChange}
                      placeholderText={t('pages.offices.attendance.range')}
                      popperPlacement="bottom-end"
                      selectsRange
                      size="large"
                      startDate={Array.isArray(value) && value.length > 0 ? value[0] : undefined}
                      disabled={isDailyAttendanceLoading}
                    />
                  </Form.Field>
                );
              }}
            />
          </Form.Container>
        </FormProvider>

        <Styled.ExportButton
          icon="u_export"
          iconSize={24}
          variant="outlined"
          color={theme.palette.generalAlt.font.primary}
          iconColor={theme.palette.generalAlt.font.primary}
          disabled={isDailyAttendanceLoading || isNoResults || isExporting}
          onClick={handleExport}
        >
          {t('buttons.toExport')}
        </Styled.ExportButton>
      </Styled.ControlsBar>
      {isDailyAttendanceLoading && <Loader />}
      {!isDailyAttendanceLoading && isNoResults && <Styled.NoResults>{t('helperText.noMatches')}</Styled.NoResults>}
      {!isDailyAttendanceLoading && !isNoResults && (
        <Table>
          <thead>
            <TableRow>
              <Styled.StaticTableHead>{t('pages.offices.attendance.date')}</Styled.StaticTableHead>
              <Styled.StaticTableHead>{t('pages.offices.attendance.maxOccupation')}</Styled.StaticTableHead>
              <Styled.StaticTableHead>{t('pages.offices.attendance.attendanceAmount')}</Styled.StaticTableHead>
              <Styled.StaticTableHead>{t('pages.offices.attendance.workspacesAmount')}</Styled.StaticTableHead>
              <Styled.StaticTableHead>{t('pages.offices.attendance.occupiedWorkspacesAmount')}</Styled.StaticTableHead>
            </TableRow>
          </thead>
          <tbody>
            {(dailyAttendance || []).map((listItem, index) => (
              <TableRow key={listItem.date || `row-${index}`}>
                <TableData>{listItem.date}</TableData>
                <TableData>{listItem.maxOccupancyPercentage}%</TableData>
                <TableData>{listItem.uniqueFaceIdCount}</TableData>
                <TableData>{listItem.totalWorkspaces}</TableData>
                <TableData>{listItem.occupiedWorkspaces}</TableData>
              </TableRow>
            ))}
          </tbody>
        </Table>
      )}
    </Styled.TabPanelContent>
  );
};

export default AttendanceStatisticsDaily;
