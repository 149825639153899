// import {notificationsManager} from '@innowise-group/ui-kit';
import {map, Observable} from 'rxjs';
import {
  Http,
  inject,
  injectable,
  GlobalContainerTypes,
  Workspace,
  RetrievePlanResponse,
  AvailableRoomsAndWorkspaces,
  AvailableRoomsAndWorkspacesRequest,
} from '../..';
import {WorkspaceResponse, WorkspacesApi, WorkspaceToCreate, WorkspaceToUpdate} from './workspaces-api.types';
import {WorkspacesNormalization} from '../workspaces-normalization';

@injectable()
class WorkspacesApiService implements WorkspacesApi {
  public static readonly type = GlobalContainerTypes.WorkspacesApi;

  @inject(GlobalContainerTypes.Http) private http: Http;
  @inject(GlobalContainerTypes.WorkspacesNormalization) private workspacesNormalization: WorkspacesNormalization;

  public editWorkspace(workspaceId: string, workspaceData: Partial<WorkspaceToUpdate>): Observable<void> {
    return this.http.PATCH(`/workspaces/${workspaceId}/assign-workspace/`, workspaceData);
  }

  public getAvailableRoomsAndWorkspaces = (
    floorId: string,
    startDate: string,
    endDate: string,
  ): Observable<AvailableRoomsAndWorkspaces[]> => {
    return this.http
      .GET<AvailableRoomsAndWorkspacesRequest[]>(
        `floors/${floorId}/retrieve-room-list/?available-only=true&occupation_after=${startDate}&occupation_before=${endDate}`,
      )
      .pipe(map((rooms) => this.workspacesNormalization.normalizeAvailableRoomsAndWorkspacesFromApi(rooms)));
  };

  public getWorkspaces(): Observable<Workspace[]> {
    return this.http
      .GET<WorkspaceResponse[]>(`workspaces/`)
      .pipe(map((workspaces) => this.workspacesNormalization.normalizeWorkspaceFromApi(workspaces, 'workspaces')));
  }

  public getWorkspaceById(id: string): Observable<Workspace> {
    return this.http
      .GET<Omit<WorkspaceResponse, 'room'> & {room: {id: number}}>(`workspaces/${id}/`)
      .pipe(
        map(
          (workspace) =>
            this.workspacesNormalization.normalizeWorkspaceFromApi(
              [{...workspace, room: workspace.room.id}],
              'workspaces',
            )[0],
        ),
      );
  }

  public getWorkspacesFromFloor(id: string): Observable<Workspace[]> {
    return this.http
      .GET<RetrievePlanResponse>(`/floors/${id}/retrieve-plan/`)
      .pipe(map((plan) => this.workspacesNormalization.normalizeWorkspaceFromApi(plan.workspaces, 'workspaces')));
  }

  public deleteWorkspaces(workspaceIds: Array<string>) {
    const params = `id=${workspaceIds.join(',')}`;
    return this.http.DELETE(`workspaces/multiple-delete/?${params}`);
    //     .pipe(
    //   tap<void>(() => {
    //     notificationsManager.success({
    //       title: 'success',
    //       subtitle: workspaceIds.length > 1 ? 'deleteWorkspaces' : 'deleteWorkspace',
    //     });
    //   }),
    // );
  }

  public createWorkspaces(workspaces: WorkspaceToCreate[]): Observable<void> {
    return this.http.POST(`/workspaces/multiple-create/`, workspaces);
    //     .pipe(
    //   tap<void>(() => {
    //     notificationsManager.success({
    //       title: 'success',
    //       subtitle: workspaces.length > 1 ? 'createWorkspaces' : 'createWorkspace',
    //     });
    //   }),
    // );
  }

  public updateWorkspaces(workspaces: WorkspaceToUpdate[]): Observable<void> {
    return this.http.PATCH(`/workspaces/multiple-update/`, workspaces);
    //     .pipe(
    //   tap<void>(() => {
    //     notificationsManager.success({
    //       title: 'success',
    //       subtitle: workspaces.length > 1 ? 'updateWorkspaces' : 'updateWorkspace',
    //     });
    //   }),
    // );
  }

  public bookingWorkspaceMode(floorId: string, start: string, end: string): Observable<Workspace[]> {
    const params = new URLSearchParams('');
    if (start && end) {
      params.append('occupation_after', start);
      params.append('occupation_before', end);
    }
    return this.http
      .GET<WorkspaceResponse[]>(`/workspaces/${floorId}/booking-mode/?${params}`)
      .pipe(map((workspaces) => this.workspacesNormalization.normalizeWorkspaceFromApi(workspaces, 'workspaces')));
  }
}

export default WorkspacesApiService;
