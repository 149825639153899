import {Controller, FormProvider, useForm} from 'react-hook-form';
import {Table, TableData, TableRow, Form, Loader, Select, SelectOption} from '@innowise-group/ui-kit';
import {useContext, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import * as Styled from './styles';
import {OfficeDetailsAttendance} from '../../services/office-details-attendance.context';

const AttendanceStatisticsMonthly = () => {
  const {
    isMonthlyAttendanceLoading,
    monthlyAttendance,
    requestMonthlyAttendance,
    isAvailableYearsLoading,
    availableYearsStatistics,
  } = useContext(OfficeDetailsAttendance);

  const {t} = useTranslation();
  const form = useForm();
  const {watch} = form;

  useEffect(() => {
    const subscription = watch(({year}) => {
      requestMonthlyAttendance(year);
    });
    return () => subscription.unsubscribe();
  }, [requestMonthlyAttendance, watch]);

  const isNoResults = useMemo(() => {
    if (!isAvailableYearsLoading && !availableYearsStatistics?.length && !isMonthlyAttendanceLoading) {
      return true;
    }
    return false;
  }, [availableYearsStatistics, isAvailableYearsLoading, isMonthlyAttendanceLoading]);

  const defaultYear = useMemo(() => {
    return !isNoResults && monthlyAttendance[0]?.year ? monthlyAttendance[0]?.year : null;
  }, [isNoResults, monthlyAttendance]);

  const availableYears = useMemo(() => {
    if (isNoResults) {
      return [];
    }
    return availableYearsStatistics
      .map((year: number) => ({
        title: String(year),
        value: String(year),
      }))
      .sort((yearPrev: SelectOption, yearNext: SelectOption) => Number(yearNext.value) - Number(yearPrev.value));
  }, [availableYearsStatistics, isNoResults]);

  return (
    <Styled.TabPanelContent>
      <Styled.ControlsBar>
        <FormProvider {...form}>
          <Form.Container name="attendance-statistics-mounthly">
            <Controller
              name="year"
              control={form?.control}
              defaultValue={defaultYear}
              render={({field: {onChange, value}}) => {
                return (
                  <Form.Field>
                    <Select
                      options={availableYears}
                      onValueChange={onChange}
                      value={value || defaultYear}
                      disabled={isNoResults}
                      placeholder={t('pages.offices.attendance.year')}
                    />
                  </Form.Field>
                );
              }}
            ></Controller>
          </Form.Container>
        </FormProvider>
      </Styled.ControlsBar>
      {(isMonthlyAttendanceLoading || isAvailableYearsLoading) && <Loader />}
      {!isMonthlyAttendanceLoading && isNoResults && !isAvailableYearsLoading && (
        <Styled.NoResults>{t('helperText.noMatches')}</Styled.NoResults>
      )}
      {!isMonthlyAttendanceLoading && !isNoResults && !isAvailableYearsLoading && (
        <Table>
          <thead>
            <TableRow>
              <Styled.StaticTableHead>{t('pages.offices.attendance.month')}</Styled.StaticTableHead>
              <Styled.StaticTableHead>{t('pages.offices.attendance.avgMaxOccupation')}</Styled.StaticTableHead>
              <Styled.StaticTableHead>{t('pages.offices.attendance.avgAttendanceAmount')}</Styled.StaticTableHead>
              <Styled.StaticTableHead>{t('pages.offices.attendance.avgMorkspacesAmount')}</Styled.StaticTableHead>
              <Styled.StaticTableHead>
                {t('pages.offices.attendance.avgOccupiedWorkspacesAmount')}
              </Styled.StaticTableHead>
            </TableRow>
          </thead>
          <tbody>
            {(monthlyAttendance || []).map((row, index) => (
              <TableRow key={`${row.month}-${index}` || `row-${index}`}>
                <TableData>{row.month}</TableData>
                <TableData>{row.averageOccupancyPercentage}</TableData>
                <TableData>{row.averageVisitorsCount}</TableData>
                <TableData>{row.averageTotalWorkspaces}</TableData>
                <TableData>{row.averageOccupiedWorkspaces}</TableData>
              </TableRow>
            ))}
          </tbody>
        </Table>
      )}
    </Styled.TabPanelContent>
  );
};

export default AttendanceStatisticsMonthly;
