import {css} from 'styled-components';
import {appearAnimation, appearAnimationReversed} from '../global-styles';
import {styled} from '../theme-provider';
import {Typography} from '../typography';
import {Icon} from '../icon';

export const Select = styled.div`
  /* display: none; */
`;

export const DropdownContainer = styled.div<{size: 'default' | 'small' | 'extra-small' | 'large'}>`
  position: relative;
  outline: none;
  width: ${({size}) =>
    size === 'large' ? '360px' : size === 'default' ? '250px' : size === 'small' ? '160px' : '70px'};
  @media (max-width: 481px) {
    width: ${({size}) => (size === 'default' ? '170px' : '130px')};
    padding: 0 15px 0 0;
  }
`;

interface DropdownHeaderProps {
  isFocused: boolean;
  disabled: boolean;
}
export const DropdownHeader = styled.div<DropdownHeaderProps>`
  position: relative;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.palette.general.lightGrey[100]};
  border-radius: 6px;
  padding: 0 24px 0 10px;
  transition: 0.2s;
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: ${({disabled}) => (disabled ? 'default' : 'pointer')};
  height: 40px;
  white-space: nowrap;
  opacity: ${({disabled}) => (disabled ? 0.4 : 1)};
  overflow: hidden;
  position: relative;
  padding: 0 24px 0 10px;

  &:hover {
    border-color: ${({theme}) => theme.palette.general.darkGrey[40]};
  }

  &:focus {
    border-color: ${({theme}) => theme.palette.general.darkGrey[60]};
  }

  &::placeholder {
    color: ${({theme}) => theme.palette.general.lightGrey[100]};
  }

  @media (max-width: 481px) {
    height: 30px;
  }
`;

export const DropdownListContainer = styled.div<{vertical: string; position: 'absolute' | 'fixed'}>`
  margin: 4px 0;
  max-height: 210px;
  width: inherit;
  position: ${({position}) => position};
  box-sizing: border-box;
  bottom: ${({vertical}) => (vertical === 'top' ? '100%' : 'default')};
  z-index: 10000;
  background-color: ${({theme}) => theme.palette.general.paper};
  border-radius: 6px 4px 4px 6px;
  overflow: hidden auto;
  border: 1px solid ${({theme}) => theme.palette.general.lightGrey[100]};
  animation: ${({vertical}) => (vertical === 'top' ? appearAnimationReversed : appearAnimation)} 0.175s ease-in-out;
  padding: 3px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${({theme}) => theme.palette.general.lightGrey[40]};
    border-radius: 0 2px 2px 0;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({theme}) => theme.palette.general.lightGrey[60]};
    border-radius: 0 2px 2px 0;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({theme}) => theme.palette.general.lightGrey[80]};
  }

  @supports not selector(::-webkit-scrollbar) {
    scrollbar-width: thin;
    scrollbar-color: ${({theme}) => theme.palette.general.lightGrey[40]};
  }
`;

export const DropDownSearchInput = styled.input`
  flex: 1;
  outline: none;
  border: none;
  font-size: 14px;
  margin: 0;
  height: 100%;
  max-width: 100%;
  background-color: transparent;
  color: ${({theme}) => theme.palette.general.darkGrey[100]};
`;

export const DropdownList = styled.ul`
  padding: 0;
  margin: 0;
  overflow: hidden;
  border: none;
`;

export const ListItemContainer = styled.div`
  display: flex;
`;

export const ListItem = styled.li<{
  readonly?: boolean;
  selected?: boolean;
  noPadding?: boolean;
  isHeader?: boolean;
  redItemColor?: boolean;
  redItemBackground?: boolean;
}>`
  display: flex;
  padding: ${({noPadding}) => (noPadding ? '0' : '0 9px 0 10px')};
  align-items: center;
  min-height: 30px;
  max-height: 55px;
  width: 100%;
  font-size: ${({isHeader}) => (isHeader ? '12px' : '14px')};
  user-select: ${({readonly = false}) => (readonly ? 'none' : 'initial')};
  color: ${({theme, redItemColor}) =>
    redItemColor ? theme.palette.general.smart : theme.palette.general.darkGrey[100]};
  transition: 0.2s;
  overflow: hidden;

  ${({selected, redItemBackground}) => {
    if (selected && redItemBackground) {
      return css`
        background-color: rgba(198, 48, 49, 0.08);
      `;
    }
    if (selected && !redItemBackground) {
      return css`
        background-color: ${({theme}) => theme.palette.general.darkGrey[20]};
      `;
    } else {
      return css`
        background-color: initial;
      `;
    }
  }}

  &:hover {
    cursor: ${({readonly = false}) => (readonly ? 'default' : 'pointer')};
    background-color: ${({theme, readonly = false}) => (readonly ? 'inherit' : theme.palette.general.darkGrey[20])};
  }
  &:last-child {
    margin: 0;
  }

  @media (max-width: 481px) {
    font-size: 10px;
  }
`;

export const Placeholder = styled(Typography)`
  color: ${({theme}) => theme.palette.general.lightGrey[100]};
`;

export const ClearButton = styled.div`
  position: absolute;
  right: 5px;
  z-index: 8;
  cursor: pointer;
  @media (max-width: 769px) {
    right: 2px;
  }
`;

export const AngleButton = styled(ClearButton)``;

export const ExpandIcon = styled(Icon)<{isOpen: boolean; isDisabled?: boolean}>`
  position: absolute;
  right: 5px;
  z-index: 8;
  cursor: pointer;
  @media (max-width: 769px) {
    right: 2px;
  }
  transition: 0.3s;
  transform: ${({isOpen}) => `rotate(${isOpen ? 180 : 0}deg)`};
  cursor: ${({isDisabled}) => (isDisabled ? 'default' : 'pointer')};
`;
