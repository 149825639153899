import {styled, Typography, css} from '@innowise-group/ui-kit';

export const EmployeesListContainer = styled.div`
  display: flex;
  margin: 5px 20px;
  height: 100px;
`;

export const EmployeesContainer = styled.div<{show: boolean}>`
  display: flex;
  overflow-y: hidden;
  white-space: nowrap;
  transform: translateY(-10%);
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);

  ${({show}) =>
    show &&
    css`
      transform: translateY(0);
    `}
  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${({theme}) => theme.palette.general.lightGrey[40]};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({theme}) => theme.palette.general.lightGrey[60]};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({theme}) => theme.palette.general.lightGrey[80]};
  }

  @supports not selector(::-webkit-scrollbar) {
    scrollbar-width: thin;
    scrollbar-color: ${({theme}) => theme.palette.general.lightGrey[40]};
  }
`;

export const EmployeeBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  margin: 5px;
  background: ${({theme}) => theme.palette.general.paper};
  border: 1px solid ${({theme}) => theme.palette.general.lightGrey[60]};
  box-sizing: border-box;
  border-radius: 10px;
  cursor: grab;
  min-width: 240px;
  height: 65px;
`;

export const EmployeeName = styled(Typography)`
  white-space: normal;
  margin-left: 5px;
  line-height: 22px;
`;

export const EmployeeInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoMessage = styled(EmployeeName)`
  color: ${({theme}) => theme.palette.general.smart};
  font-size: 12px;
  line-height: 14px;
`;
