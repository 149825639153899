import {Select, SelectProps} from '@innowise-group/ui-kit';
import React, {memo, useEffect, useMemo, useState} from 'react';
import {FloorPlan, FloorsApiService, useService} from '@innowise-group/core';
import {useTranslation} from 'react-i18next';

interface FloorsSelectProps extends SelectProps {
  officeId?: string;
}

const FloorsSelect: React.FC<FloorsSelectProps> = ({
  officeId,
  defaultValue,
  isFieldChanged,
  onValueChange,
  ...props
}) => {
  const [floors, setFloors] = useState<FloorPlan[]>([]);
  const floorsApi = useService(FloorsApiService);

  const {t} = useTranslation();

  useEffect(() => {
    const subscriber = floorsApi.getListFloors().subscribe(setFloors);
    return () => subscriber.unsubscribe();
  }, [floorsApi]);

  const officesFloors = useMemo(() => {
    if (officeId) {
      const floorsByOfficeId = floors.filter((floor) => floor.office === officeId);
      return [
        ...floorsByOfficeId.filter((item) => !item.isVirtual),
        ...floorsByOfficeId.filter((item) => item.isVirtual),
      ];
    }
    return [];
  }, [floors, officeId]);

  const transformValue = (id: string): Promise<string> => {
    const currentDefaultValue = defaultValue;
    const currentIsFieldChanged = isFieldChanged;
    const currentOfficeFloors = officesFloors;

    return new Promise((resolve, reject) => {
      if (!id && !currentDefaultValue && !currentIsFieldChanged) {
        reject('No ID provided for valueTransform function');
      } else if (currentIsFieldChanged) {
        if (currentOfficeFloors.length) {
          onValueChange(currentOfficeFloors[0]?.id);
          resolve(`${currentOfficeFloors[0].number} ${t('pages.offices.officeDetails.floor')}`);
        } else {
          resolve('');
        }
      } else {
        const floorById = floors.find((floor) => (id ? floor.id === id : floor.id === currentDefaultValue));
        if (floorById) {
          const {number, isVirtual} = floorById;
          resolve(`${number} ${t(`pages.offices.officeDetails.${isVirtual ? 'virtualFloor' : 'floor'}`)}`);
        }
      }
    });
  };

  return (
    <Select
      options={officesFloors.map((floor) => ({
        value: floor.id,
        title: `${floor.number} ${t(`pages.offices.officeDetails.${floor.isVirtual ? 'virtualFloor' : 'floor'}`)}`,
      }))}
      asyncValueTransformer={transformValue}
      placeholder={t('placeholders.choseFloor')}
      onValueChange={onValueChange}
      {...props}
    />
  );
};

export default memo(FloorsSelect);
