import {injectable} from 'inversify';
import {intervalToDuration} from 'date-fns';
import {getLanguageFromLS} from '@innowise-group/utilities';
import {
  FilterData,
  GeotrackNormalization,
  EmployeeStatusesResponse,
  EmployeeStatusesEnglishKeys,
  EmployeeAnalyticsResponse,
  EmployeeAnalyticsData,
  EmployeeAnalyticsEnglishKeys,
  EmployeeStatisticsResponse,
  EmployeeStatistics,
  EmployeeStatisticsFailure,
  EmployeeStatisticsData,
  ChatbotStatusResponse,
  HrmDictionaryResponse,
  GeotrackContainerTypes,
  ChatbotStatuses,
  FilterDataPayload,
  HrmDictionary,
  OrgUnit,
  EmployeeDateTimeMarksData,
  EmployeeVisitsByDatesResponse,
  EmployeeVisitsResponse,
  EmployeeVisitByDateResponse,
  EmployeeVisitByDate,
  EmployeeVisitByDateEngKeys,
} from '../../types';
import {getSortedAndMappedEmployeeMarkStatusData} from './utils';
import {classNames} from '../../components/personal-statistics/utils';
import {isEmpty} from 'lodash';
@injectable()
class GeotrackNormalizationService implements GeotrackNormalization {
  public static readonly type = GeotrackContainerTypes.GeotrackNormalization;

  constructor() {
    this.normalizeFilterPayload = this.normalizeFilterPayload.bind(this);
    this.normalizeEmployeeStatusesFromApi = this.normalizeEmployeeStatusesFromApi.bind(this);
    this.normalizeEmployeeAnalyticsFromApi = this.normalizeEmployeeAnalyticsFromApi.bind(this);
    this.normalizeEmployeeStatisticsFromApi = this.normalizeEmployeeStatisticsFromApi.bind(this);
    this.normalizeEmployeeMarkStatusByDateFromApi = this.normalizeEmployeeMarkStatusByDateFromApi.bind(this);
    this.normalizeEmployeeVisits = this.normalizeEmployeeVisits.bind(this);
    this.normalizeEmployeeVisit = this.normalizeEmployeeVisit.bind(this);
  }

  private concat = (...arrays) => [].concat(...arrays.filter(Array.isArray));

  public normalizeFilterPayload(filterData: FilterData): FilterDataPayload {
    if (filterData) {
      const {
        statuses,
        officeIds,
        chatbotLabels,
        jobTitles,
        workFormats,
        legalOffices,
        professionalLevels,
        managerialLevels,
        probationStatuses,
        timeWorked,
        entryTime,
        entryOffice,
        workingDay,
        date_after,
        date_before,
        unit,
        division,
        department,
        daysOfWeek,
        ...rest
      } = filterData;

      return {
        status: statuses as string[],
        office_address: this._normalizeOfficeIds(officeIds as string[]),
        location: this._normalizeOfficeIds(entryOffice as string[]),
        chat_bot_label: chatbotLabels as string[],
        job_title: jobTitles as string[],
        work_format: workFormats as string[],
        legal_office_address: legalOffices as string[],
        professional_level: professionalLevels as string[],
        managerial_level: this._normalizeManagerialLevelFilter(managerialLevels as string[]),
        probationary_period: probationStatuses as string[],
        time_range: timeWorked as string[],
        late: entryTime as string[],
        mark_status: workingDay as string[],
        date_range: this._getDateRange(date_after, date_before),
        unit: unit as string[],
        division: division as string[],
        department: department as string[],
        days_of_week: daysOfWeek as string,
        ...rest,
      };
    }
  }

  public normalizeEmployeeStatusesFromApi({
    date_time,
    last_date_time,
    location_country,
    preferred_name,
    name_eng,
    surname_eng,
    has_faceid,
    managerial_level,
    mark_status,
    office_location_country,
    legal_location_country,
    ...status
  }: EmployeeStatusesResponse): EmployeeDateTimeMarksData {
    const language = getLanguageFromLS();
    const localeIndex = +(language === 'ru');

    const localeCity = this._getEmployeeLocaleField('city', localeIndex);
    const localeOffice = this._getEmployeeLocaleField('office_address', localeIndex);

    let legalOfficeAddress = status[this._getEmployeeLocaleField('legal_office_address', localeIndex)];
    let legalOfficeCity: string;
    const parts = legalOfficeAddress?.split(', ');

    if (parts.length < 2) {
      legalOfficeCity = legal_location_country;
    } else if (legalOfficeAddress) {
      legalOfficeCity = parts[0];
      legalOfficeAddress = parts.slice(1).join(', ');
    }

    return {
      ...status,
      time: date_time?.slice(date_time.indexOf('T') + 1, date_time.indexOf('+')) ?? null,
      working_time:
        date_time &&
        last_date_time &&
        intervalToDuration({start: +new Date(date_time), end: +new Date(last_date_time)}),
      face_id: has_faceid,
      entry_office: status[this._getEmployeeLocaleField('location', localeIndex)],
      entry_office_city: status[this._getEmployeeLocaleField('location_city', localeIndex)],
      entry_office_country: location_country,
      office_city: office_location_country && status[localeCity] && `${status[localeCity]}, ${office_location_country}`,
      office_address: status[localeOffice],
      job_title: status[this._getEmployeeLocaleField('job_title', localeIndex)],
      work_format: status[this._getEmployeeLocaleField('work_format', localeIndex)],
      status: status[this._getEmployeeLocaleField('status', localeIndex)],
      preferred_name: localeIndex ? preferred_name.split(' ') : [surname_eng, name_eng],
      legal_location_country: legal_location_country,
      legal_office_address: legalOfficeAddress,
      legal_office_city: legalOfficeCity,
      probationary_period: status[this._getEmployeeLocaleField('probationary_period', localeIndex)],
      managerial_level: this._normalizeManagerialLevelField(managerial_level),
      mark_status: this._normalizeMarkStatus(mark_status),
      all_date_time_marks: status.all_date_time_marks.map((dateTimeMark) => ({
        ...dateTimeMark,
        location: dateTimeMark[this._getEmployeeLocaleField('location', localeIndex)],
        location_city: dateTimeMark[this._getEmployeeLocaleField('location_city', localeIndex)],
        date_time:
          dateTimeMark?.date_time?.slice(
            dateTimeMark?.date_time.indexOf('T') + 1,
            dateTimeMark?.date_time.indexOf('+'),
          ) ?? null,
      })),
    } as EmployeeDateTimeMarksData;
  }

  public normalizeEmployeeAnalyticsFromApi({
    preferred_name,
    name_eng,
    surname_eng,
    managerial_level,
    ...data
  }: EmployeeAnalyticsResponse): EmployeeAnalyticsData {
    const language = getLanguageFromLS();
    const localeIndex = +(language === 'ru');
    return {
      ...data,
      employee_id: data.hrm_id,
      preferred_name: localeIndex ? preferred_name.split(' ') : [surname_eng, name_eng],
      legal_office_address: data[this._getEmployeeLocaleField('legal_office_address', localeIndex)],
      job_title: data[this._getEmployeeLocaleField('job_title', localeIndex)],
      work_format: data[this._getEmployeeLocaleField('work_format', localeIndex)],
      probationary_period: data[this._getEmployeeLocaleField('probationary_period', localeIndex)],
      managerial_level: this._normalizeManagerialLevelField(managerial_level),
    };
  }

  public normalizeEmployeeMarkStatusByDateFromApi(data) {
    return getSortedAndMappedEmployeeMarkStatusData(data);
  }

  public normalizeEmployeeStatisticsFromApi(response: EmployeeStatisticsResponse): EmployeeStatisticsData {
    return this._isFailedStatisticsResponse(response)
      ? this._getDefaultStatisticsData()
      : this._getStatisticsData(response);
  }

  public normalizeOrgUnitTypes(orgUnits: OrgUnit[]): OrgUnit[] {
    return orgUnits
      ?.filter((unit) => unit.orgUnitTypeId === 6 || unit.orgUnitTypeId === 4 || unit.orgUnitTypeId === 1)
      .sort((a, b) => b.orgUnitTypeId - a.orgUnitTypeId);
  }

  public normalizeHrmDictionaryEntries(entries: HrmDictionaryResponse): HrmDictionary[] {
    const language = getLanguageFromLS();
    const title = (entry) => entry.translations[+(language === 'ru')]?.translation;
    return entries.map((entry) =>
      entry.values.sort((a, b) => a.orderValue - b.orderValue).map((value) => ({title: title(value), value: value.id})),
    );
  }

  public normalizeChatbotStatuses(statuses: ChatbotStatusResponse): ChatbotStatuses {
    return statuses.map((status) => ({title: status.name, value: '' + status.id}));
  }

  private _getEmployeeLocaleField(
    field:
      | keyof Omit<EmployeeStatusesResponse, EmployeeStatusesEnglishKeys>
      | keyof Omit<EmployeeAnalyticsResponse, EmployeeAnalyticsEnglishKeys>
      | keyof Omit<EmployeeVisitByDateResponse, EmployeeVisitByDateEngKeys>,
    index: number,
  ): keyof EmployeeStatusesResponse | keyof EmployeeAnalyticsResponse | keyof EmployeeVisitByDateResponse {
    return [(field + '_eng') as EmployeeStatusesEnglishKeys, field][index];
  }

  private _normalizeManagerialLevelFilter(values: string[]): string[] {
    return values?.map((value) => (value === 'Не задан' ? 'Not defined' : value)) ?? null;
  }

  private _normalizeManagerialLevelField(value: string): string {
    if (value !== 'Not defined') return value;
    return 'P';
  }

  private _normalizeMarkStatus(status: string) {
    return status
      ? status.slice(0, 1).toLowerCase() +
          status.slice(1).replace(/( [A-Za-z])/g, (group) => group.at(-1).toUpperCase())
      : 'Not defined';
  }

  private _normalizeOfficeIds(officeIds: string[]) {
    const sep = ', ';

    return officeIds.map((str) => {
      const preResult = str.split(sep).slice(2).join(sep);

      if (!preResult) {
        return str;
      }

      return preResult;
    });
  }

  private _getDateRange(from: Date | string | null, to: Date | string | null): Date[] {
    if (!from) return [];

    const fromDate = typeof from === 'string' ? new Date(from) : from;
    const toDate = typeof to === 'string' ? new Date(to) : to || new Date();

    return [fromDate, toDate];
  }

  private _isFailedStatisticsResponse(response: EmployeeStatisticsResponse): response is EmployeeStatisticsFailure {
    const responseKeys = Object.keys(response);
    return responseKeys.includes('error');
  }

  private _getDefaultStatisticsData(): EmployeeStatisticsData {
    return {
      workingDays: {
        confirmedVisit: 0,
        unconfirmedVisit: 0,
        remoteWorkWithVisit: 0,
        remoteWork: 0,
        absent: 0,
      },
      workFormatOffenders: {
        offence: 0,
        compliance: 0,
      },
      latecomers: {
        timeBefore11_30: 0,
        timeAfter11_30: 0,
        notDefined: 0,
      },
    };
  }

  private _getStatisticsData({
    types_of_working_days_chart: {confirmed_visit, unconfirmed_visit, remote_work, remote_work_with_visit, absent},
    work_format_offenders_chart: {is_offenders, is_good_guys},
    latecomers_chart: {in_time, late, no_data},
  }: EmployeeStatistics): EmployeeStatisticsData {
    return {
      workingDays: {
        confirmedVisit: confirmed_visit,
        unconfirmedVisit: unconfirmed_visit,
        remoteWorkWithVisit: remote_work_with_visit,
        remoteWork: remote_work,
        absent,
      },
      workFormatOffenders: {
        offence: is_offenders,
        compliance: is_good_guys,
      },
      latecomers: {
        timeBefore11_30: in_time,
        timeAfter11_30: late,
        notDefined: no_data,
      },
    };
  }

  public normalizeEmployeeVisits(response: EmployeeVisitsByDatesResponse): EmployeeVisitsResponse {
    if (isEmpty(response)) return {};

    const {visit_types_chart, latecomers_chart, work_format_offenders_chart} = response;

    const employeeVisitsStatistics = {};
    visit_types_chart &&
      Object.entries(visit_types_chart).forEach(([key, value]) => {
        employeeVisitsStatistics[key] = {visitType: value.visit_type, classNames: classNames[value.visit_type]};
      });

    latecomers_chart &&
      Object.entries(latecomers_chart).forEach(([key, value]) => {
        employeeVisitsStatistics[key] = {
          ...employeeVisitsStatistics[key],
          isLatecomer: value,
        };
      });

    work_format_offenders_chart &&
      Object.entries(work_format_offenders_chart).forEach(([key, value]) => {
        employeeVisitsStatistics[key] = {
          ...employeeVisitsStatistics[key],
          isOffender: value.is_offender,
          classNames: value.is_offender
            ? [employeeVisitsStatistics[key].classNames, 'offender'].join(' ')
            : employeeVisitsStatistics[key].classNames,
        };
      });

    return employeeVisitsStatistics;
  }

  private _normalizeOfficeAddress(city: string | null, address: string | null) {
    let officeAddress: string | null;

    if (city && address) {
      officeAddress = `${city}, ${address}`;
    } else if (city || address) {
      officeAddress = `${city || ''} ${address || ''}`;
    } else {
      officeAddress = null;
    }
    return officeAddress;
  }
  public normalizeEmployeeVisit(response: EmployeeVisitByDateResponse): EmployeeVisitByDate | null {
    if (isEmpty(response)) return null;
    const language = getLanguageFromLS();
    const localeIndex = +(language === 'ru');

    const definitionOfficeCity = response[this._getEmployeeLocaleField('definition_office_city', localeIndex)];
    const definitionOfficeAddress = response[this._getEmployeeLocaleField('definition_office_address', localeIndex)];

    const workTime = response?.office_hours
      ? {
          hours: response.office_hours,
          minutes: response.office_minutes,
        }
      : null;

    return {
      status: response[this._getEmployeeLocaleField('status', localeIndex)],
      chatBotStatus: response.chat_bot_status,
      hasFaceId: response.has_faceid,
      enterDateTimeOnly: response.enter_date_time_only,
      officeAddress: this._normalizeOfficeAddress(definitionOfficeCity, definitionOfficeAddress),
      workTime: workTime,
    };
  }
}

export default GeotrackNormalizationService;
